/**@jsx jsx */
import { useQuery } from "@apollo/client";
import { CSVLink } from "react-csv";
import { Fragment, useState } from "react";
import { Button, Grid, Heading, jsx, Text } from "theme-ui";
import { GET_ALL_EXPERIENCES } from "../../apollo/queries/experiences/getAllExperiences";
import { GET_ALL_USERS } from "../../apollo/queries/users/getAllUsers";
import { Referral, User } from "../../apollo/schemas/api";
import { UserDashboardLayout } from "../layout/user-dashboard-layout";

interface IUserDashboard {
  user?: User;
}

export const ReferralList: React.FC<IUserDashboard> = ({ user }) => {
  const { data, loading } = useQuery(GET_ALL_EXPERIENCES);

  const referrals = data?.getAllReferrals;
  const userData = data?.getAllReferrals.map((ref) => {
    const userCsvData = {
      Nombre: ref.name,
      Usuario: ref.user.name,
      "Correo Electrónico": ref.email,
      Teléfono: ref.phone,
      Empresa: ref.company,
      Puesto: ref.position,
    };
    return userCsvData;
  });
  return (
    <UserDashboardLayout user={user}>
      <Fragment>
        <div
          sx={{
            gap: 3,
            p: 4,
            bg: "background",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {loading && <div>Loading...</div>}
          {data && (
            <Fragment>
              <div
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 256px",
                  marginBottom: "16px",
                }}
              >
                <Heading as="h2">Referencias</Heading>
                <CSVLink
                  data={userData}
                  filename={"Lista_de_usuarios.csv"}
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    position: "relative",
                    left: "100px",
                  }}
                >
                  <Button>Descargar CSV</Button>
                </CSVLink>
              </div>
              <div>
                <div
                  sx={{
                    display: "grid",
                    gridTemplateColumns: [
                      "256px 128px 128px 128px 256px 128px",
                    ],
                    gap: 1,
                    py: 3,
                    borderTop: "1px solid",
                    borderBottom: "1px solid",
                    borderColor: "muted",
                  }}
                >
                  <Heading as="h5">Nombre</Heading>
                  <Heading as="h5">Usuario</Heading>
                  <Heading as="h5">Empresa</Heading>
                  <Heading as="h5">Teléfono</Heading>
                  <Heading as="h5">Email</Heading>
                  <Heading as="h5">Puesto</Heading>
                </div>
                {(referrals as Referral[]).map((ref) => (
                  <Fragment>
                    <div
                      sx={{
                        cursor: "pointer",
                        display: "grid",
                        gridTemplateColumns: [
                          "256px 128px 128px 128px 256px 128px",
                        ],
                        gap: 1,
                        py: 3,
                        bg: "transparent",
                        outline: "none",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid",
                        borderColor: "muted",
                      }}
                    >
                      <Fragment>
                        <Text sx={{ textAlign: "left" }}>{ref.name}</Text>
                        <Text sx={{ textAlign: "left" }}>{ref.user.name}</Text>
                        <Text sx={{ textAlign: "left" }}>{ref.company}</Text>
                        <Text sx={{ textAlign: "left" }}>{ref.phone}</Text>
                        <Text sx={{ textAlign: "left" }}>{ref.email}</Text>
                        <Text sx={{ textAlign: "left" }}>{ref.position}</Text>
                      </Fragment>
                    </div>
                  </Fragment>
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    </UserDashboardLayout>
  );
};
