/**@jsx jsx */
import { Heading, jsx, Spinner, Text } from "theme-ui";
import { Job } from "../../../apollo/schemas/api";
import { Fragment, useContext } from "react";
import { Link } from "gatsby";
import { useQuery } from "@apollo/client";
import { GET_ALL_JOBS } from "../../../apollo/queries/jobs/getAllJobs";
import { AppContext } from "../../../context/app-context";
import { format } from "date-fns";

const JobLink = ({ job }: { job: Job }) => {
  let bgColor = "muted";
  let color = "text";
  const createdAt = format(new Date(job.createdAt["@ts"]), "dd/MM/yyyy");
  const updatedAt = format(new Date(job.updatedAt["@ts"]), "dd/MM/yyyy");

  switch (job.status) {
    case "PENDING":
      bgColor = "lemonchiffon";
      break;
    case "UNPUBLISHED":
      bgColor = "muted";
      break;
    case "REVIEW":
      bgColor = "#DAF9DA";
      break;
    case "FINISHED":
      bgColor = "header";
      color = "background";
      break;
    default:
      bgColor = "muted";
  }

  return (
    <Fragment>
      <Link
        to={`/app/${job._id}`}
        sx={{
          p: 3,
          bg: bgColor,
          width: "100%",
          display: "inline-block",
          color: color,
          textDecoration: "none",
          textAlign: "left",
        }}
      >
        <Heading as="h3">{job.title}</Heading>

        <Text sx={{ fontSize: 0 }}>Publicado: {createdAt}</Text>
        <Text sx={{ fontSize: 0 }}>Última edición: {updatedAt}</Text>
        <Text sx={{ fontSize: 0 }}>Status: {job.status}</Text>

        <p sx={{ fontSize: 1 }}>Descripción: {job.description}</p>
      </Link>
    </Fragment>
  );
};

export const JobList = () => {
  const { data, loading } = useQuery<{ getAllJobs: Job[] }>(GET_ALL_JOBS, {
    fetchPolicy: "cache-first",
  });
  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100%",
        }}
      >
        <Spinner />
      </div>
    );
  console.log({ data });
  const jobs = data?.getAllJobs;

  return (
    <Fragment>
      {jobs.map((job) => (
        <JobLink job={job} key={job._id} />
      ))}
    </Fragment>
  );
};
