/**@jsx jsx */
import { Form, Formik } from "formik";
import { Button, Heading, jsx } from "theme-ui";
import { useMutation } from "@apollo/client";
import { navigate } from "gatsby-link";

import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";
// import {
//   JobInput,
//   JobType,
//   MutationUpdateJobArgs,
// } from "../../../apollo/schemas/api";
import { GET_CURRENT_USER } from "../../../apollo/queries/users/getCurrentUser";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import { CheckBox } from "../../form/checkbox";
import { InputField } from "../../form/input";

export const JobInformation = () => {
  const initialValues: Partial<any & { jobType: boolean }> = {
    address: {
      city: "",
      country: "",
    },
    salary: {
      payroll: "",
      receipt: "",
    },
    availability: {
      date: "",
      inmediate: false,
    },
    jobType: false,
    salaryRange: "",
    jobCommute: "",
  };

  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => {
      navigate("/app/crear-empleo/modulos-sap");
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleSubmit = async ({
    values,
  }: {
    values: Partial<any & { jobType: boolean }>;
  }) => {
    const id = localStorage.getItem("jobid");
    const type: any = values.jobType ? "FULL" : "PART";
    delete values.jobType;
    const jobInput: any = { ...values, type };
    const input: any = {
      id,
      jobInput: jobInput,
    };
    await updateJob({
      variables: {
        ...input,
      },
    });
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={2} steps={6} />
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Crea una nueva oportunidad
        </Heading>
        <p>Agrega algunos datos extras para crear el empleo.</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit({ values })}
        >
          {({ isSubmitting, values }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset
                sx={{
                  borderColor: "muted",
                  borderStyle: "dashed",
                  display: "grid",
                  gap: 3,
                }}
              >
                <legend sx={{ fontSize: 1 }}>Dirección</legend>
                <InputField
                  name="address.city"
                  label="Ciudad"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="address.country"
                  label="País"
                  type="text"
                  inputType="input"
                />
              </fieldset>
              <fieldset
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["1fr 1fr"],
                  gap: 3,
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Expectativa salarial</legend>
                <InputField
                  name="salary.payroll"
                  label="Planilla"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="salary.receipt"
                  label="RxH"
                  type="text"
                  inputType="input"
                />
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Disponibilidad</legend>
                <div>
                  <p>¿Inmediata?</p>
                  <CheckBox name="availability.inmediate" type="checkbox" />
                </div>
                {!values.availability.inmediate && (
                  <InputField
                    name="availability.date"
                    label="Fecha Disponibilidad"
                    type="date"
                    inputType="input"
                  />
                )}
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Informacion Extra</legend>
                <InputField
                  name="jobCommute"
                  label="¿Trabajo remoto?"
                  type="text"
                  inputType="input"
                  placeholder="Remoto temporal"
                />
                <div>
                  <p>¿Empleo de tiempo completo?</p>
                  <CheckBox name="jobType" type="checkbox" />
                </div>
                {!values.availability.inmediate && (
                  <InputField
                    name="salaryRange"
                    label="Rango de salario"
                    type="text"
                    inputType="input"
                  />
                )}
              </fieldset>
              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Cargando..." : "Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
