import { gql } from "@apollo/client";
import {
  JOB_FRAGMENT,
  AVAILABILITY_FRAGMENT,
  CANDITATES_FRAGMENT,
  MODULE_FRAGMENT,
  PARAMS_FRAGMENT,
  SALARY_FRAGMENT,
  SKILLS_FRAGMENT,
} from "../../fragments/jobFragment";

export const GET_JOB = gql`
  ${JOB_FRAGMENT}
  ${AVAILABILITY_FRAGMENT}
  ${CANDITATES_FRAGMENT}
  ${MODULE_FRAGMENT}
  ${PARAMS_FRAGMENT}
  ${SALARY_FRAGMENT}
  ${SKILLS_FRAGMENT}
  query($id: String!) {
    getJob(id: $id) {
      ...JobFragment
      ...ParamsFragment
      ...CandidatesFragment
      ...ModuleFragment
      ...SalaryFragment
      ...SkillsFragment
      ...AvailabilityFragment
    }
  }
`;
