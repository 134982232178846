/**@jsx jsx */

import { useQuery } from "@apollo/client";
import { Link } from "gatsby";
import { Heading, jsx, Spinner, Text } from "theme-ui";
import { GET_JOB } from "../../../apollo/mutations/jobs/getJob";
import { GET_ALL_JOBS } from "../../../apollo/queries/jobs/getAllJobs";
import { Job, User } from "../../../apollo/schemas/api";
import { JobList } from "./jobs-list";
import { JobDetailPage } from "./job-detail";

export const JobsPage = ({ jobId, user }: { jobId?: string; user: User }) => {
  return (
    <div sx={{ display: "grid", gap: 3 }}>
      {jobId ? <JobDetailPage jobId={jobId} user={user} /> : <JobList />}
    </div>
  );
};
