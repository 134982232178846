/**@jsx jsx */
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Heading, jsx, Spinner, Text } from "theme-ui";

import { InputField } from "../form/input";
import { CheckBox } from "../form/checkbox";
import { SelectField } from "../form/select";
import { UPDATE_USER } from "../../apollo/mutations/users/updateUser";
import { Layout } from "../layout/main-layout";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import { DocumentType, User, UserInput } from "../../apollo/schemas/api";
import { UploadFile } from "../form/upload-file";
import { Helmet } from "react-helmet";

declare global {
  interface Window {
    cloudinary: any;
  }
}

export const UserProfileEdit: React.FC = () => {
  const [cloudinary, setCloudinary] = useState(null);
  const [cloudResponse, setCloudResponse] = useState<
    { secureURL: string; index: number; filename: string }[]
  >([
    {
      filename: "",
      index: 0,
      secureURL: "",
    },
  ]);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const indexRef = useRef<number>();

  indexRef.current = activeIndex;
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      navigate("/app/editar/");
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: UserInput;
    actions: FormikHelpers<UserInput>;
  }) => {
    try {
      await updateUser({
        variables: {
          userInput: {
            ...values,
            identification: {
              ...values.identification,
              number: Number(values.identification.number),
            },
            curriculum: cloudResponse[activeIndex].secureURL,
            isConsultant: values.isConsultant,
          },
        },
      });
    } catch (err) {
      console.log({ err });
      throw err;
    }
  };

  const handleUpload = ({ error, result }) => {
    if (!error && result && result.event === "success") {
      setCloudResponse((c) => {
        const findIndex = c.findIndex(
          (item) => item.index === indexRef.current
        );
        if (findIndex >= 0) {
          c[findIndex] = {
            ...c[findIndex],
            secureURL: result.info.secure_url,
            filename: result.info.original_filename,
          };
        }
        return [...c];
      });
    }
  };

  const {
    data,
    loading,
  }: { data: { getCurrentUser: User }; loading: boolean } = useQuery(
    GET_CURRENT_USER
  );

  const handleHover = () => {
    if (!cloudinary) {
      const cloud = window.cloudinary?.createUploadWidget(
        {
          cloudName: "praxisnet",
          uploadPreset: "praxisnet",
          sources: ["local", "url", "google_drive"],
        },
        (error, result) => handleUpload({ error, result })
      );
      setCloudinary(cloud);
    }
  };
  const handleUploadClick = (i) => {
    setActiveIndex(i);
    cloudinary.open();
  };

  useEffect(() => {
    const initialiazeState = () => {
      const user = data?.getCurrentUser;
      user?.curriculum &&
        setCloudResponse([
          {
            secureURL: user.curriculum,
            filename: user.name,
            index: 0,
          },
        ]);
    };
    if (data) {
      initialiazeState();
    }
  }, [data]);

  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );

  const user = data.getCurrentUser;

  const initialValues: UserInput = {
    phone: user.phone ?? "",
    identification: {
      type: user.identification?.type ?? ("DNI" as DocumentType.Dni),
      number: user.identification?.number,
    },
    birthdate: user.birthdate ?? "",
    address: {
      city: user.address?.city,
      country: user.address?.country,
    },
    salary: {
      payroll: user.salary?.payroll,
      receipt: user.salary?.receipt,
    },
    availability: {
      inmediate: user.availability?.inmediate ?? false,
      date: user.availability?.date ?? "",
    },
    isConsultant: user.isConsultant ?? true,
  };

  return (
    <Layout>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <div
        sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}
        onMouseEnter={handleHover}
      >
        <Heading as="h1">Acerca tuyo</Heading>
        <p>Cuentanos un poco acerca de ti.</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting, values }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Documento de Indentidad</legend>
                <div
                  sx={{
                    display: "grid",
                    gridTemplateColumns: ["128px 1fr"],
                    gap: 3,
                  }}
                >
                  <SelectField
                    name="identification.type"
                    label="Tipo"
                    type="text"
                    options={[
                      {
                        id: 1,
                        value: "DNI",
                        name: "DNI",
                      },
                      {
                        id: 2,
                        value: "CE",
                        name: "CE",
                      },
                    ]}
                    value={values.identification.type}
                  />
                  <InputField
                    name="identification.number"
                    label="Número"
                    type="text"
                    inputType="input"
                  />
                </div>
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Datos personales</legend>

                <InputField
                  name="phone"
                  label="Teléfono"
                  type="tel"
                  inputType="input"
                />
                <InputField
                  name="birthdate"
                  label="Fecha de nacimiento"
                  type="date"
                  inputType="input"
                />
                <InputField
                  name="address.city"
                  label="Ciudad"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="address.country"
                  label="País"
                  type="text"
                  inputType="input"
                />
              </fieldset>

              <fieldset
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["1fr 1fr"],
                  gap: 3,
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Expectativa salarial</legend>
                <InputField
                  name="salary.payroll"
                  label="Planilla"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="salary.receipt"
                  label="RxH"
                  type="text"
                  inputType="input"
                />
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Disponibilidad</legend>
                <div>
                  <p>¿Inmediata?</p>
                  <CheckBox name="availability.inmediate" type="checkbox" />
                </div>
                {!values.availability.inmediate && (
                  <InputField
                    name="availability.date"
                    label="Fecha Disponibilidad"
                    type="date"
                    inputType="input"
                  />
                )}
              </fieldset>
              <div>
                <p>¿Eres Consultor?</p>
                <CheckBox name="isConsultant" type="checkbox" />
              </div>

              {user.curriculum && <Text>Curriculum ✅</Text>}
              <UploadFile
                fileName={cloudResponse[0]?.filename}
                handleClick={handleUploadClick}
                label={"Certificado"}
                index={0}
                disabled={!cloudinary}
              />
              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
