import { gql } from "@apollo/client";
import { USER_FRAGEMENT } from "../../fragments/userFragment";

export const UPDATE_USER = gql`
  ${USER_FRAGEMENT}
  mutation($userInput: UserInput!) {
    updateUser(userInput: $userInput) {
      ...UserFragemnt
    }
  }
`;
