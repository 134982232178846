/**@jsx jsx */
import { useQuery } from "@apollo/client";
import { Fragment, useState } from "react";
import { Button, Grid, Heading, jsx, Text } from "theme-ui";
import { GET_ALL_USERS } from "../../apollo/queries/users/getAllUsers";
import { User } from "../../apollo/schemas/api";
import { UserDashboardLayout } from "../layout/user-dashboard-layout";
import { CSVLink } from "react-csv";

interface IUserDashboard {
  user?: User;
}

export const UserList: React.FC<IUserDashboard> = ({ user }) => {
  const { data, loading } = useQuery(GET_ALL_USERS);
  const [userId, setUserId] = useState<string | null>(null);

  const userData = data?.getUsers.map((user: User) => {
    const userCsvData = {
      Nombre: user.name,
      "Correo Electrónico": user.email,
      Teléfono: user.phone,
      Curriculum: user.curriculum,
      "DNI / CE": `${user?.identification?.type ?? ""}  ${
        user?.identification?.number ?? ""
      }`,
      Role: user.role,
      Cumpleaños: user.birthdate,
      "¿Es Consultor?": user.isConsultant,
    };
    return userCsvData;
  });

  return (
    <UserDashboardLayout user={user}>
      <Fragment>
        <div
          sx={{
            gap: 3,
            p: 4,
            bg: "background",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {loading && <div>Loading...</div>}
          {data && (
            <Fragment>
              <div
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 256px",
                  marginBottom: "16px",
                }}
              >
                <Heading as="h2">Usuarios</Heading>
                <CSVLink
                  data={userData}
                  filename={"Lista_de_usuarios.csv"}
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    position: "relative",
                    left: "100px",
                  }}
                >
                  <Button>Descargar CSV</Button>
                </CSVLink>
              </div>
              <div>
                <div
                  sx={{
                    display: "grid",
                    gridTemplateColumns: [
                      "256px 128px 128px 256px 128px 128px",
                    ],
                    gap: 1,
                    py: 3,
                    borderTop: "1px solid",
                    borderBottom: "1px solid",
                    borderColor: "muted",
                  }}
                >
                  <Heading as="h5">Nombre</Heading>
                  <Heading as="h5">DNI/CE</Heading>
                  <Heading as="h5">Teléfono</Heading>
                  <Heading as="h5">Email</Heading>
                  <Heading as="h5">Cumpleaños</Heading>
                  <Heading as="h5">Rol</Heading>
                </div>
                {(data?.getUsers as User[]).map((user) => (
                  <Fragment>
                    <div
                      onClick={() => setUserId(user._id)}
                      sx={{
                        cursor: "pointer",
                        display: "grid",
                        gridTemplateColumns: [
                          "256px 128px 128px 256px 128px 128px",
                        ],
                        gap: 1,
                        py: 3,
                        bg: "transparent",
                        outline: "none",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid",
                        borderColor: "muted",
                      }}
                    >
                      {userId === user._id ? (
                        <div
                          sx={{
                            gridColumn: "1/7",
                            bg: "muted",
                            display: "grid",
                            gridTemplateColumns: ["1fr 1fr 1fr 1fr"],
                            fontSize: 1,
                            textAlign: "left",
                            p: 3,
                            gap: 3,
                          }}
                        >
                          <div
                            sx={{
                              display: "grid",
                              gap: 2,
                              px: 3,
                              borderRight: (t) =>
                                `1px solid ${t.colors.blue[0]}`,
                            }}
                          >
                            <Heading as="h3">Datos Personales</Heading>
                            <Text>
                              <b>Nombre:</b> {user?.name}
                            </Text>
                            <Text>
                              <b>
                                {user?.identification?.type ??
                                  "Indentificación"}
                                :{" "}
                              </b>
                              {user?.identification?.number ?? "-"}
                            </Text>
                            <Text>
                              <b>Telefono: </b>
                              {user?.phone ?? "-"}
                            </Text>
                            <Text>
                              <b>Email: </b>
                              {user?.email ?? "-"}
                            </Text>
                            <Text>
                              <b>Fecha de Nacimiento: </b>
                              {user?.birthdate ?? "-"}
                            </Text>
                            <Text>
                              <b>Salario en Planilla: </b>
                              {user?.salary?.payroll ?? "-"}
                            </Text>
                            <Text>
                              <b>Salario en RxH: </b>
                              {user?.salary?.receipt ?? "-"}
                            </Text>
                            <Text>
                              <b>Disponibilidad: </b>
                              {user?.availability?.inmediate
                                ? "Inmediata"
                                : user?.availability?.date ?? "-"}
                            </Text>
                            <Text>
                              <a
                                href={user.curriculum}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Curriculum
                              </a>
                            </Text>
                            {/* <div sx={{ display: "flex", gap: 2 }}>
                              <Button
                                sx={{
                                  py: "2px!important",
                                  bg: (t) =>
                                    user.role === "ADMIN"
                                      ? "primary"
                                      : t.colors.blue[0],
                                }}
                                disabled={user.role === "ADMIN"}
                              >
                                Admin
                              </Button>
                              <Button
                                sx={{
                                  py: "2px!important",
                                  bg: (t) =>
                                    user.role === "PUBLIC"
                                      ? "primary"
                                      : t.colors.blue[0],
                                }}
                                disabled={user.role === "PUBLIC"}
                              >
                                User
                              </Button>
                            </div> */}
                          </div>
                          <div
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                              px: 3,
                              borderRight: (t) =>
                                `1px solid ${t.colors.blue[0]}`,
                            }}
                          >
                            <Heading as="h3">Modulos:</Heading>
                            {user?.modules?.map((module, index) => (
                              <div
                                sx={{
                                  border: (t) =>
                                    `1px solid ${t.colors.blue[0]}`,
                                  p: 1,
                                }}
                                key={index}
                              >
                                <Text>
                                  <b>Modulo:</b> {module.module}
                                </Text>
                                <Text>
                                  <b>Experiencia: </b> {module.experience} años.
                                </Text>
                                {module.certified && (
                                  <a
                                    href={module.certificate}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Certificado
                                  </a>
                                )}
                              </div>
                            ))}
                          </div>
                          <div
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                              px: 3,
                              borderRight: (t) =>
                                `1px solid ${t.colors.blue[0]}`,
                            }}
                          >
                            <Heading as="h3">Rubros:</Heading>
                            <div>
                              {user.fields?.map((field, index) => (
                                <Text sx={{ fontSize: 0 }} key={index}>
                                  {field}
                                </Text>
                              ))}
                            </div>
                          </div>
                          <div
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                              px: 3,
                            }}
                          >
                            <Heading as="h3">Referencias:</Heading>
                            <div sx={{ display: "grid", gap: 2 }}>
                              {user?.experiences?.map((exp, index) => (
                                <div
                                  sx={{
                                    border: (t) =>
                                      `1px solid ${t.colors.blue[0]}`,
                                    p: 1,
                                  }}
                                  key={index}
                                >
                                  <Text>
                                    <b>Puesto: </b>
                                    {exp.position}
                                  </Text>
                                  <Text>
                                    <b>Empresa: </b>
                                    {exp.referral.company}
                                  </Text>
                                  <Text>
                                    <b>Referencia: </b>
                                    {exp.referral.name}
                                  </Text>
                                  <Text>
                                    <b>Telefono: </b>
                                    {exp.referral.phone}
                                  </Text>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Fragment>
                          <Text sx={{ textAlign: "left" }}>{user?.name}</Text>
                          <Text sx={{ textAlign: "left" }}>
                            {user?.identification?.number ?? "-"}
                          </Text>
                          <Text sx={{ textAlign: "left" }}>
                            {user?.phone ?? "-"}
                          </Text>
                          <Text sx={{ textAlign: "left" }}>
                            {user?.email ?? "-"}
                          </Text>
                          <Text sx={{ textAlign: "left" }}>
                            {user?.birthdate ?? "-"}
                          </Text>
                          <Text sx={{ textAlign: "left" }}>
                            {user?.role ?? "-"}
                          </Text>
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    </UserDashboardLayout>
  );
};
