/**@jsx jsx */
import { jsx } from "theme-ui";
import { navigate, PageProps } from "gatsby";
import { Router } from "@reach/router";
import { Spinner } from "@theme-ui/components";
import { useQuery } from "@apollo/client";

import { Login } from "../components/auth/login";
import { ForgotPassword } from "../components/auth/forgot-password";
import { UsePasswordCode } from "../components/auth/use-password-code";
import { PasswordChange } from "../components/auth/password-change";
import { UserDashboard } from "../components/dashboard/user-dashboard";

import { GET_CURRENT_USER } from "../apollo/queries/users/getCurrentUser";

import { Layout } from "../components/layout/main-layout";
import { UserList } from "../components/dashboard/list-users";
import { ProtectedRoute, Route } from "../reach-router/Route";
import { User } from "../apollo/schemas/api";
import { Register } from "../components/user-onboarding/register";
import { UserProfile } from "../components/user-onboarding/user-profile";
import { SapModule } from "../components/user-onboarding/sap-module";
import { UserAbilities } from "../components/user-onboarding/user-abilities";
import { UserReferences } from "../components/user-onboarding/user-references";
import { CreateJob } from "../components/jobs-module/job-form/create-job";
import { UserFields } from "../components/user-onboarding/user-fields";
import { JobSapModule } from "../components/jobs-module/job-form/sap-module";
import { JobFields } from "../components/jobs-module/job-form/job-fields";
import { JobAbilities } from "../components/jobs-module/job-form/job-abilities";
import { JobEvaluation } from "../components/jobs-module/job-form/job-evaluation";
import { JobInformation } from "../components/jobs-module/job-form/job-information";
import { ReferralList } from "../components/dashboard/list-referrals";
import { Fragment, useEffect, useState } from "react";
import { EditJob } from "../components/jobs-module/job-form-edit/edit-job";
import { EditJobFields } from "../components/jobs-module/job-form-edit/edit-fields";
import { EditJobAbilities } from "../components/jobs-module/job-form-edit/edit-abilities";
import { EditJobSapModule } from "../components/jobs-module/job-form-edit/sap-module";
import { EditJobEvaluation } from "../components/jobs-module/job-form-edit/edit-evaluation";
import { UserProfileEdit } from "../components/user-edit/user-profile-edit";
import { SapModuleEdit } from "../components/user-edit/user-module-edit";
import { UserFieldsEdit } from "../components/user-edit/user-fields-edit";
import { UserAbilitiesEdit } from "../components/user-edit/user-abilities-edit";
import { EditProfile } from "../components/dashboard/edit-profile";
import { UserReferencesList } from "../components/user-edit/user-references-list";
import { UserReferencesEdit } from "../components/user-edit/user-references-edit";

const App: React.FC<PageProps> = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>(null);

  useEffect(() => {
    if (typeof window !== undefined) {
      setLoggedIn(!!localStorage.getItem("token"));
    }
  }, []);

  const {
    data,
  }: { data: { getCurrentUser: User | null }; loading: boolean } = useQuery(
    GET_CURRENT_USER,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data) {
      setUser(data.getCurrentUser);
      const finishedOnboarding =
        data.getCurrentUser?.onboarding.finished ?? false;
      finishedOnboarding && navigate(data.getCurrentUser.onboarding.link);
      setLoading(false);
    }
  }, [data, loading]);

  if (loading)
    return (
      <Layout>
        <div
          sx={{
            display: ["grid"],
            placeItems: ["center"],
            position: ["fixed"],
            top: [0],
            right: [0],
            left: [0],
            bottom: [0],
            zIndex: -1,
          }}
        >
          <Spinner />
        </div>
      </Layout>
    );

  return (
    <Router basepath="/app/">
      <ProtectedRoute component={UserDashboard} path="/" user={user} />
      <ProtectedRoute component={EditProfile} path="/editar" user={user} />
      <ProtectedRoute component={UserDashboard} path="/:jobId" user={user} />
      <ProtectedRoute
        component={PasswordChange}
        path="cambiar-password"
        user={user}
      />
      {/* JOB CREATION */}

      <ProtectedRoute component={CreateJob} path="crear-empleo" user={user} />
      <ProtectedRoute
        component={JobInformation}
        path="crear-empleo/informacion"
        user={user}
      />
      <ProtectedRoute
        component={JobSapModule}
        path="crear-empleo/modulos-sap"
        user={user}
      />
      <ProtectedRoute
        component={JobFields}
        path="crear-empleo/rubros"
        user={user}
      />
      <ProtectedRoute
        component={JobAbilities}
        path="crear-empleo/habilidades"
        user={user}
      />
      <ProtectedRoute
        component={JobEvaluation}
        path="crear-empleo/evaluacion"
        user={user}
      />

      {/* JOB EDIT */}
      <ProtectedRoute
        component={EditJob}
        path="/:jobId/editar-info"
        user={user}
      />
      <ProtectedRoute
        component={EditJobAbilities}
        path="/:jobId/editar-habilidades"
        user={user}
      />
      <ProtectedRoute
        component={EditJobFields}
        path="/:jobId/editar-rubros"
        user={user}
      />
      <ProtectedRoute
        component={EditJobSapModule}
        path="/:jobId/editar-modulos"
        user={user}
      />
      <ProtectedRoute
        component={EditJobSapModule}
        path="/:jobId/editar-modulos"
        user={user}
      />
      <ProtectedRoute
        component={EditJobEvaluation}
        path="/:jobId/editar-evaluacion"
        user={user}
      />
      {/* USER EDIT */}
      <ProtectedRoute
        component={UserAbilitiesEdit}
        path="/editar-habilidades"
        user={user}
      />
      <ProtectedRoute
        component={UserFieldsEdit}
        path="/editar-rubros"
        user={user}
      />
      <ProtectedRoute
        component={SapModuleEdit}
        path="/editar-modulos"
        user={user}
      />
      <ProtectedRoute
        component={UserProfileEdit}
        path="/editar-perfil"
        user={user}
      />
      <ProtectedRoute
        component={UserReferencesList}
        path="/editar-referencias"
        user={user}
      />
      <ProtectedRoute
        component={UserReferencesEdit}
        path="/editar-referencias/:id"
        user={user}
      />
      <ProtectedRoute
        component={UserReferences}
        path="/agregar-referencias/"
        user={user}
      />

      {/* USER LISTS */}
      <ProtectedRoute component={UserList} path="usuarios" user={user} />
      <ProtectedRoute component={ReferralList} path="referencias" user={user} />
      {/* AUTHENTICATION */}
      <Route component={UserProfile} path="registrarme/informacion-personal" />
      <Route component={SapModule} path="registrarme/modulos-sap" />
      <Route
        component={UserReferences}
        path="registrarme/referencias-laborales"
        user={user}
      />
      <Route component={UserAbilities} path="registrarme/habilidades" />
      <Route component={UserFields} path="registrarme/rubros" />

      <Route component={UsePasswordCode} path="confirmar-codigo" />
      <Route component={ForgotPassword} path="recuperar-password" />
      <Route component={Login} path="ingresar" />
      <Route component={Register} path="registrarme" />
    </Router>
  );
};

export default App;
