/**@jsx jsx */
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import { Radio, jsx, Label } from "theme-ui";

type CheckboxType = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  type: string;
};

export const CheckBox = ({ ...props }: CheckboxType) => {
  const [field, {}, { setValue }] = useField(props);
  return (
    <div
      role="group"
      aria-labelledby="checkbox-group"
      sx={{ display: "flex", alignItems: "center", gap: 3 }}
    >
      <Label
        sx={{
          width: "max-content",
          cursor: "pointer",
          userSelect: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Radio
          {...field}
          {...props}
          id={field.name}
          checked={field.checked}
          onChange={() => setValue(true)}
        />
        Si
      </Label>
      <Label
        sx={{
          width: "max-content",
          cursor: "pointer",
          userSelect: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Radio
          {...field}
          {...props}
          id={field.name}
          checked={!field.checked}
          onChange={() => setValue(false)}
        />
        No
      </Label>
    </div>
  );
};
