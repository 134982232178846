/**@jsx jsx */
import { Form, Formik } from "formik";
import { Button, Heading, jsx, Spinner } from "theme-ui";
import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";
import { Range } from "../../form/range";
import {
  EnglishLevelInput,
  Job,
  JobInput,
  MutationUpdateJobArgs,
  SoftSkillsInput,
} from "../../../apollo/schemas/api";
import { useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../../apollo/queries/users/getCurrentUser";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import { GET_JOB } from "../../../apollo/queries/jobs/getJob";

const softSkills = [
  {
    name: "trabajoEquipo",
    label: "Trabajo en Equipo",
    parent: "softSkills",
  },
  {
    name: "liderazgo",
    label: "Liderazgo",
    parent: "softSkills",
  },
  {
    name: "comunicacionEfectiva",
    label: "Comunicación Efectiva",
    parent: "softSkills",
  },
  {
    name: "escuchaActiva",
    label: "Escucha Activa",
    parent: "softSkills",
  },
  {
    name: "adaptabilidad",
    label: "Adaptabilidad",
    parent: "softSkills",
  },
  {
    name: "creatividad",
    label: "Creatividad",
    parent: "softSkills",
  },
  {
    name: "espirituServicio",
    label: "Espíritu de Servicio",
    parent: "softSkills",
  },
  {
    name: "comunicacion",
    label: "Comunicación",
    parent: "softSkills",
  },
  {
    name: "empatia",
    label: "Empatía",
    parent: "softSkills",
  },
  {
    name: "proactividad",
    label: "Proactividad",
    parent: "softSkills",
  },
  {
    name: "gestionConflicto",
    label: "Gestión de Conflictos",
    parent: "softSkills",
  },
  {
    name: "gestionCambio",
    label: "Gestión de Cambio",
    parent: "softSkills",
  },
];

const englishSkills = [
  {
    name: "speaking",
    label: "Fluidez",
    parent: "englishLevel",
  },
  {
    name: "reading",
    label: "Lectura",
    parent: "englishLevel",
  },
  {
    name: "writing",
    label: "Escritura",
    parent: "englishLevel",
  },
];

interface IValues {
  softSkills: {
    trabajoEquipo: number;
    liderazgo: number;
    comunicacionEfectiva: number;
    escuchaActiva: number;
    adaptabilidad: number;
    creatividad: number;
    espirituServicio: number;
    comunicacion: number;
    empatia: number;
    proactividad: number;
    gestionConflicto: number;
    gestionCambio: number;
  };
  englishLevel: {
    speaking: number;
    writing: number;
    reading: number;
  };
}

export const EditJobAbilities = ({ jobId }: { jobId: string }) => {
  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => {
      navigate(`/app/${jobId}/`);
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleSubmit = async ({ values }: { values: IValues }) => {
    const softSkills: SoftSkillsInput = { ...values.softSkills };
    const englishLevel: EnglishLevelInput = { ...values.englishLevel };
    const jobInput: JobInput = {
      softSkills,
      englishLevel,
    };
    const input: MutationUpdateJobArgs = {
      id: jobId,
      jobInput: jobInput,
    };
    await updateJob({
      variables: {
        ...input,
      },
    });
  };

  const { data, loading } = useQuery<{ getJob: Job }>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });

  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );

  const job = data.getJob;

  const initialValues = {
    softSkills: {
      trabajoEquipo: job.softSkills?.trabajoEquipo ?? 1,
      liderazgo: job.softSkills?.liderazgo ?? 1,
      comunicacionEfectiva: job.softSkills?.comunicacionEfectiva ?? 1,
      escuchaActiva: job.softSkills?.escuchaActiva ?? 1,
      adaptabilidad: job.softSkills?.adaptabilidad ?? 1,
      creatividad: job.softSkills?.creatividad ?? 1,
      espirituServicio: job.softSkills?.espirituServicio ?? 1,
      comunicacion: job.softSkills?.comunicacion ?? 1,
      empatia: job.softSkills?.empatia ?? 1,
      proactividad: job.softSkills?.proactividad ?? 1,
      gestionConflicto: job.softSkills?.gestionConflicto ?? 1,
      gestionCambio: job.softSkills?.gestionCambio ?? 1,
    },
    englishLevel: {
      speaking: job.englishLevel?.speaking ?? 0,
      writing: job.englishLevel?.writing ?? 0,
      reading: job.englishLevel?.reading ?? 0,
    },
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Edita {job.title}
        </Heading>
        <p>
          Por favor selecciona el puntaje para las habilidades del postulante.
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit({ values })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Nivel de Inglés</legend>
                <div>
                  {englishSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Habilidades blandas:</legend>
                <div>
                  {softSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
