/**@jsx jsx */
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Heading, jsx, Text } from "theme-ui";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useMutation } from "@apollo/client";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { InputField } from "../../form/input";
import { CheckBox } from "../../form/checkbox";
import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";
import { CREATE_JOB } from "../../../apollo/mutations/jobs/createJob";
import { CreateJobInput, Job } from "../../../apollo/schemas/api";
import { navigate } from "gatsby-link";

export const CreateJob = () => {
  const initialValues = {
    title: "",
    position: "",
    description: "",
    consultantJob: false,
    jobCategory: "",
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    setEditorState(editorState);
  };
  const [createJob] = useMutation(CREATE_JOB, {
    onCompleted: (data) => {
      const { _id } = data.createJob as Job;
      localStorage.setItem("jobid", _id);
      navigate("/app/crear-empleo/informacion");
    },
    onError: (err) => {
      console.log({ err });
    },
  });

  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: {
      title: string;
      position: string;
      description: string;
      consultantJob: boolean;
      jobCategory: string;
    };
    actions: FormikHelpers<{
      title: string;
      position: string;
      description: string;
      consultantJob: boolean;
      jobCategory: string;
    }>;
  }) => {
    const contentState = editorState.getCurrentContent();
    const createJobInput: CreateJobInput = {
      title: values.title,
      position: values.position,
      description: values.description,
      content: stateToHTML(contentState),
      consultantJob: values.consultantJob,
      jobCategory: values.jobCategory,
    };
    try {
      const response = await createJob({
        variables: {
          createJobInput,
        },
      });
    } catch (err) {
      console.log({ err });
    }

    actions.setSubmitting(false);
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={1} steps={6} />
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Crea una nueva oportunidad
        </Heading>
        <p>Necesitamos algunos datos básicos.</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <InputField
                name="title"
                label="Título"
                type="text"
                inputType="input"
              />
              <InputField
                name="position"
                label="Cargo"
                type="text"
                inputType="input"
              />
              <InputField
                name="description"
                label="Descripción"
                type="text"
                inputType="textarea"
              />
              <div
                sx={{
                  boxShadow: "inset 0px 0.5px 4px rgba(96, 97, 112, 0.32)",
                  borderRadius: ["16px", "24px"],
                  height: 416,
                  p: 3,
                  overflow: "scroll",
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    lineHeight: 2,
                    fontWeight: "heading",
                    px: 3,
                    color: (t) => t.colors.dark[0],
                  }}
                >
                  Contenido:
                </Text>
                {mounted && (
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={handleChange}
                  />
                )}
              </div>
              <div>
                <p>¿Trabajo para consultor?</p>
                <CheckBox name="consultantJob" type="checkbox" />
              </div>
              <InputField
                name="jobCategory"
                label="Categoría"
                type="text"
                inputType="input"
              />
              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Cargando" : "Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
