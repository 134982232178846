import { gql } from "@apollo/client";

export const PASSWORD_RESET_CODE = gql`
  mutation($code: String!) {
    usePasswordResetCode(code: $code) {
      token
      user {
        _id
        emailConfirmed
      }
    }
  }
`;
