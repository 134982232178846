/**@jsx jsx */
import { Fragment, InputHTMLAttributes } from "react";
import { jsx, Label } from "theme-ui";
import { useField } from "formik";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  parent?: string;
};

export const Range = ({ parent, ...props }: InputFieldProps) => {
  const { label } = props;
  const [{ value, name }, _, { setValue }] = useField(props);
  return (
    <div
      sx={{
        display: "flex",
        mb: [4],
        borderBottom: (t) => `1px solid ${t.colors.light[1]}`,
      }}
    >
      <Label
        htmlFor={name}
        sx={{ textAlign: "left", fontWeight: "heading", fontSize: 1 }}
      >
        {label}
      </Label>
      <div>
        <input
          type="range"
          min={parent === "englishLevel" ? 1 : 1}
          max={parent === "englishLevel" ? 3 : 5}
          id={name}
          value={value}
          list="tickmarks"
          onChange={(e) => {
            setValue(+e.target.value);
          }}
        />
        <datalist
          id="tickmarks"
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <option value={1} label={parent === "englishLevel" ? "Basic" : "1"} />
          <option value={2} label={parent === "englishLevel" ? "Inter" : "2"} />
          <option value={3} label={parent === "englishLevel" ? "Avanz" : "3"} />
          {parent !== "englishLevel" && (
            <Fragment>
              <option value={4} label="4" />
              <option value={5} label="5" />
            </Fragment>
          )}
        </datalist>
      </div>
    </div>
  );
};
