/**@jsx jsx */
import { Fragment } from "react";
import { jsx } from "theme-ui";
import { User } from "../../apollo/schemas/api";
import { JobsPage } from "../jobs-module/pages.tsx/jobs";
import { UserDashboardLayout } from "../layout/user-dashboard-layout";

interface IUserDashboard {
  user?: User;
  jobId?: string;
}

export const UserDashboard: React.FC<IUserDashboard> = ({ user, jobId }) => {
  const isLoggedIn = !!localStorage.getItem("token");
  return (
    <UserDashboardLayout user={user}>
      <Fragment>
        <div
          sx={{
            gap: 3,
            p: 4,
            bg: "background",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {!!user && isLoggedIn && <JobsPage jobId={jobId} user={user} />}
        </div>
      </Fragment>
    </UserDashboardLayout>
  );
};
