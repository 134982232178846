/**@jsx jsx */
import { Link } from "gatsby";
import { Fragment } from "react";
import { Heading, jsx } from "theme-ui";
import { User } from "../../apollo/schemas/api";
import { UserDashboardLayout } from "../layout/user-dashboard-layout";

interface IUserDashboard {
  user?: User;
  jobId?: string;
}

export const EditProfile: React.FC<IUserDashboard> = ({ user }) => {
  return (
    <UserDashboardLayout user={user}>
      <Fragment>
        <div
          sx={{
            gap: 3,
            p: 4,
            bg: "background",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <Heading as="h2">Editar mi perfil</Heading>
          <ul>
            <li>
              <Link to="/app/editar-perfil">Editar perfil</Link>
            </li>
            {user.isConsultant && (
              <Fragment>
                <li>
                  <Link to="/app/editar-modulos">Editar experiencia SAP</Link>
                </li>
                <li>
                  <Link to="/app/editar-rubros">
                    Editar rubros de experiencia
                  </Link>
                </li>
                <li>
                  <Link to="/app/editar-habilidades">Editar habilidades</Link>
                </li>
                <li>
                  <Link to="/app/editar-referencias">Editar referencias</Link>
                </li>
              </Fragment>
            )}
          </ul>
        </div>
      </Fragment>
    </UserDashboardLayout>
  );
};
