import { gql } from "@apollo/client";

export const EXPERIENCE_FRAGMENT = gql`
  fragment ExperienceFragment on Experience {
    _id
    position
    startDate
    endDate
    currentJob
    description
    referral {
      name
      phone
      email
      position
      company
    }
  }
`;
