/**@jsx jsx */
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { jsx, Text, Heading, Button } from "theme-ui";
import { useApolloClient, useMutation } from "@apollo/client";
import { Layout } from "./main-layout";
import { LOGOUT_USER } from "../../apollo/mutations/auth/logout";
import React from "react";
import { User } from "../../apollo/schemas/api";

interface IUserDashboard {
  user?: User;
}

const Icon = () => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.5L0.763767 3.19269e-07L-6.66364e-08 0.816468L6.32729 6.5L4.40417e-07 12.1835L0.763768 13L8 6.5Z"
      fill="black"
    />
  </svg>
);

const SidebarButton = ({ handleClick, children, active = false }) => (
  <Button
    variant="buttons.clientMenu"
    sx={{ bg: active && "muted" }}
    onClick={handleClick}
  >
    {children}
    <div sx={{ ml: "auto" }}>
      <Icon />
    </div>
  </Button>
);

export const UserDashboardLayout: React.FC<IUserDashboard> = ({
  children,
  user,
}) => {
  const client = useApolloClient();
  const [logout] = useMutation(LOGOUT_USER);

  const handleLogout = async () => {
    logout();
    localStorage.removeItem("token");
    navigate("/app/");
    await client.clearStore();
  };
  const location = useLocation();

  const sideLinks = [
    {
      name: "Empleos",
      handleClick: () => navigate("/app/"),
      path: ["/app/", "/app"],
      role: ["ADMIN"],
    },
    {
      name: "Postulaciones",
      handleClick: () => navigate("/app/"),
      path: ["/app/", "/app"],
      role: ["PUBLIC"],
    },
    {
      name: "Crear nuevo empleo",
      handleClick: () => navigate("/app/crear-empleo/"),
      path: ["/app/crear-empleo", "/app/crear-empleo/"],
      role: ["ADMIN"],
    },
    {
      name: "Editar Perfil",
      handleClick: () => navigate("/app/editar"),
      path: ["/app/editar/", "/app/editar"],
      role: ["PUBLIC"],
    },
    {
      name: "Ver usuarios",
      handleClick: () => navigate("/app/usuarios/"),
      path: ["/app/usuarios/", "/app/usuarios"],
      role: ["ADMIN"],
    },
    {
      name: "Ver referencias",
      handleClick: () => navigate("/app/referencias"),
      path: ["/app/referencias/", "/app/referencias"],
      role: ["ADMIN"],
    },
    {
      name: "Cambiar constraseña",
      handleClick: () => navigate("/app/cambiar-password"),
      path: ["/app/cambiar-password/", "/app/cambiar-password"],
      role: ["PUBLIC", "ADMIN"],
    },
    {
      name: "Cerrar sesión",
      handleClick: handleLogout,
      role: ["PUBLIC", "ADMIN"],
    },
  ];

  const filteredLinks = sideLinks.filter((link) =>
    link.role.includes(user?.role)
  );

  return (
    <Layout>
      <div
        sx={{
          bg: "muted",
          width: ["100%"],
          py: [3],
          px: [3, 5],
          display: "grid",
          gap: 3,
          gridTemplateColumns: [null, null, "356px 1fr"],
          gridTemplateRows: [null, null, "max-content 1fr"],
          minHeight: [null, null, null, "calc(100vh - 176px)"],
        }}
      >
        <div
          sx={{
            pt: [3],
            bg: "background",
            p: 4,
            gap: 4,
            gridColumn: [null, null, "1/2"],
          }}
        >
          <Heading as="h3">{user?.name}</Heading>
          <Text sx={{ fontSize: [0] }}>Telf: {user?.phone}</Text>
          <Text sx={{ fontSize: [0] }}>Email: {user?.email}</Text>
          {user?.identification && (
            <Text sx={{ fontSize: [0] }}>
              {user?.identification?.type}: {user?.identification?.number}
            </Text>
          )}
        </div>

        <div
          sx={{
            gridColumn: [null, null, "2/3"],
            gridRow: [null, null, "1/3"],
            overflow: "hidden",
          }}
        >
          {children}
        </div>
        <div
          sx={{
            p: 3,
            mb: 3,
            bg: "background",
            height: "100%",
          }}
        >
          {filteredLinks.map((link, i) => (
            <SidebarButton
              handleClick={link.handleClick}
              active={link.path?.includes(location.pathname) ?? false}
              key={i}
            >
              {link.name}
            </SidebarButton>
          ))}
        </div>
      </div>
    </Layout>
  );
};
