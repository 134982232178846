/**@jsx jsx */
import { Form, Formik } from "formik";
import { Button, Heading, jsx } from "theme-ui";
import * as Yup from "yup";
import { Layout } from "../layout/main-layout";
import { BreadCrumbs } from "../navigation/bread-crumbs";
import { Range } from "../form/range";
import {
  EnglishLevelInput,
  SoftSkillsInput,
  UserInput,
} from "../../apollo/schemas/api";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../apollo/mutations/users/updateUser";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";

const softSkills = [
  {
    name: "trabajoEquipo",
    label: "Trabajo en Equipo",
    parent: "softSkills",
  },
  {
    name: "liderazgo",
    label: "Liderazgo",
    parent: "softSkills",
  },
  {
    name: "comunicacionEfectiva",
    label: "Comunicación Efectiva",
    parent: "softSkills",
  },
  {
    name: "escuchaActiva",
    label: "Escucha Activa",
    parent: "softSkills",
  },
  {
    name: "adaptabilidad",
    label: "Adaptabilidad",
    parent: "softSkills",
  },
  {
    name: "creatividad",
    label: "Creatividad",
    parent: "softSkills",
  },
  {
    name: "espirituServicio",
    label: "Espíritu de Servicio",
    parent: "softSkills",
  },
  {
    name: "comunicacion",
    label: "Comunicación",
    parent: "softSkills",
  },
  {
    name: "empatia",
    label: "Empatía",
    parent: "softSkills",
  },
  {
    name: "proactividad",
    label: "Proactividad",
    parent: "softSkills",
  },
  {
    name: "gestionConflicto",
    label: "Gestión de Conflictos",
    parent: "softSkills",
  },
  {
    name: "gestionCambio",
    label: "Gestión de Cambio",
    parent: "softSkills",
  },
];

const englishSkills = [
  {
    name: "speaking",
    label: "Fluidez",
    parent: "englishLevel",
  },
  {
    name: "reading",
    label: "Lectura",
    parent: "englishLevel",
  },
  {
    name: "writing",
    label: "Escritura",
    parent: "englishLevel",
  },
];

const validationSchema = Yup.object().shape({
  softSkills: Yup.object().shape({
    trabajoEquipo: Yup.number().min(0).max(5).required("Obligatorio"),
    liderazgo: Yup.number().min(0).max(5).required("Obligatorio"),
    comunicacionEfectiva: Yup.number().min(0).max(5).required("Obligatorio"),
    escuchaActiva: Yup.number().min(0).max(5).required("Obligatorio"),
    adaptabilidad: Yup.number().min(0).max(5).required("Obligatorio"),
    creatividad: Yup.number().min(0).max(5).required("Obligatorio"),
    espirituServicio: Yup.number().min(0).max(5).required("Obligatorio"),
    comunicacion: Yup.number().min(0).max(5).required("Obligatorio"),
    empatia: Yup.number().min(0).max(5).required("Obligatorio"),
    proactividad: Yup.number().min(0).max(5).required("Obligatorio"),
    gestionConflicto: Yup.number().min(0).max(5).required("Obligatorio"),
    gestionCambio: Yup.number().min(0).max(5).required("Obligatorio"),
  }),
  englishLevel: Yup.object().shape({
    speaking: Yup.number().min(0).max(3).required("Obligatorio"),
    reading: Yup.number().min(0).max(3).required("Obligatorio"),
    writing: Yup.number().min(0).max(3).required("Obligatorio"),
  }),
});

interface IValues {
  softSkills: {
    trabajoEquipo: number;
    liderazgo: number;
    comunicacionEfectiva: number;
    escuchaActiva: number;
    adaptabilidad: number;
    creatividad: number;
    espirituServicio: number;
    comunicacion: number;
    empatia: number;
    proactividad: number;
    gestionConflicto: number;
    gestionCambio: number;
  };
  englishLevel: {
    speaking: number;
    writing: number;
    reading: number;
  };
}

export const UserAbilities = () => {
  const initialValues = {
    softSkills: {
      trabajoEquipo: 3,
      liderazgo: 3,
      comunicacionEfectiva: 3,
      escuchaActiva: 3,
      adaptabilidad: 3,
      creatividad: 3,
      espirituServicio: 3,
      comunicacion: 3,
      empatia: 3,
      proactividad: 3,
      gestionConflicto: 3,
      gestionCambio: 3,
    },
    englishLevel: {
      speaking: 0,
      writing: 0,
      reading: 0,
    },
  };

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      navigate("/app/registrarme/referencias-laborales");
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleSubmit = async ({ values }: { values: IValues }) => {
    const softSkills: SoftSkillsInput = { ...values.softSkills };
    const englishLevel: EnglishLevelInput = { ...values.englishLevel };
    const userInput: UserInput = {
      softSkills,
      englishLevel,
    };
    await updateUser({
      variables: {
        userInput: {
          ...userInput,
          onboarding: {
            finished: false,
            link: "/app/registrarme/referencias-laborales",
          },
        },
      },
    });
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={5} steps={6} />
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Acerca de tus habilidades
        </Heading>
        <p>
          Por favor selecciona el puntaje para tus habilidades. Marca 1 si es
          básico o 5 si es avanzado.
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit({ values })}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Nivel de Inglés</legend>
                <div>
                  {englishSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Habilidades blandas</legend>
                <div>
                  {softSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Cargando..." : "Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
