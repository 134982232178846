/**@jsx jsx */
import { useMutation } from "@apollo/client";
import { Form, Formik, FormikHelpers, useField } from "formik";
import { navigate } from "gatsby";
import { InputHTMLAttributes, useState } from "react";
import { Button, Heading, Input, jsx, Label } from "theme-ui";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import { GET_CURRENT_USER } from "../../../apollo/queries/users/getCurrentUser";
import {
  JobInput,
  MutationUpdateJobArgs,
  UserInput,
} from "../../../apollo/schemas/api";

import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";

interface FieldsInterface {
  value: string;
  name: string;
}

type CheckboxType = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  type: string;
};

const Checkbox = ({ label, ...props }: CheckboxType) => {
  const [field] = useField(props);
  return (
    <Label
      sx={{
        bg: field.checked ? "header" : "muted",
        p: 2,
        color: "background",
        width: "max-content",
        borderRadius: "8px",
        transition: "ease-in-out",
        transitionDuration: "0.3s",
      }}
    >
      <Input {...field} {...props} id={field.name} sx={{ display: "none" }} />
      {label}
    </Label>
  );
};

const fields: FieldsInterface[] = [
  { value: "TELECOMUNICACIONES", name: "TELECOMUNICACIONES" },
  { value: "CONSUMO_MASIVO", name: "CONSUMO MASIVO" },
  { value: "INDUSTRIAL", name: "INDUSTRIAL" },
  { value: "CERAMICO", name: "CERAMICO" },
  { value: "MINERIA", name: "MINERIA" },
  { value: "EDUCACION", name: "EDUCACION" },
  { value: "AEROPORTUARIO", name: "AEROPORTUARIO" },
  { value: "AGROINDUSTRIAL", name: "AGROINDUSTRIAL" },
  { value: "CONSTRUCCION", name: "CONSTRUCCION" },
  { value: "INMOBILIARIA", name: "INMOBILIARIA" },
  { value: "HIDROCARBUROS", name: "HIDROCARBUROS" },
  { value: "INDUSTRIA", name: "INDUSTRIA" },
  { value: "QUIMICA", name: "QUIMICA" },
  { value: "RETAIL", name: "RETAIL" },
  { value: "TEXTIL", name: "TEXTIL" },
  { value: "PLASTICOS", name: "PLASTICOS" },
  { value: "ENERGIA", name: "ENERGIA" },
  { value: "COMERCIALIZACION", name: "COMERCIALIZACION" },
  { value: "OIL_GAS", name: "OIL GAS" },
  { value: "BANCA", name: "BANCA" },
  { value: "MANUFACTURA", name: "MANUFACTURA" },
  { value: "AUTOMOTRIZ", name: "AUTOMOTRIZ" },
  { value: "PETROLERA", name: "PETROLERA" },
];

export const JobFields = () => {
  const [updateJob] = useMutation(UPDATE_JOB, {
    onError: (err) => {
      console.log({ err });
    },
    onCompleted: () => {
      navigate("/app/crear-empleo/habilidades");
    },
  });
  const initialValues: { fields: string[] } = {
    fields: [],
  };
  const [rubros, setRubros] = useState<string[]>([]);
  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: { fields: string[] };
    actions: FormikHelpers<{ fields: string[] }>;
  }) => {
    const id = localStorage.getItem("jobid");
    const jobInput: JobInput = {
      fields: values.fields,
    };
    const input: MutationUpdateJobArgs = {
      id,
      jobInput,
    };
    await updateJob({
      variables: {
        ...input,
      },
    });
  };
  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={4} steps={6} />
        <Heading as="h1">Crea una nueva oportunidad</Heading>
        <p>Marca los rubros donde los postulantes deben tener experiencia.</p>
        <form>
          <label htmlFor="rubros"></label>
          <input id="rubros" type="text" name="rubros" />
          <Button
            aria-label="submit"
            type="submit"
            sx={{
              width: "100%",
            }}
          >
            Continuar
          </Button>
        </form>
      </div>
    </Layout>
  );
};
