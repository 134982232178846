/**@jsx jsx */
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import { Link, navigate } from "gatsby";
import React, { Fragment } from "react";
import * as Yup from "yup";
import { Button, Heading, jsx, Spinner } from "theme-ui";
import { CREATE_USER } from "../../apollo/mutations/users/createUser";
import { UPDATE_USER } from "../../apollo/mutations/users/updateUser";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import { InputField } from "../form/input";
import { Layout } from "../layout/main-layout";
import { BreadCrumbs } from "../navigation/bread-crumbs";

const createProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muy corto!")
    .max(50, "Muy largo!")
    .required("Obligatorio"),
  email: Yup.string().email("Correo inválido!").required("Obligatorio"),
  password: Yup.string()
    .min(6, "Debe tener 6 carácteres mínimo!")
    .max(16, "Debe tener 16 carácteres máximo!"),
});

interface ILogin {
  name: string;
  email: string;
  password: string;
  "confirm-password": string;
}

const REGISTER_QUERY = gql`
  query {
    getCurrentUser {
      name
      email
    }
  }
`;

export const Register: React.FC = () => {
  const client = useApolloClient();
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: async (data) => {
      localStorage.setItem("token", data.createUser.token);
      await client.resetStore();
      navigate("/app/registrarme/informacion-personal");
    },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onCompleted: () => {
      navigate("/app/");
    },
  });
  const { data, loading, error } = useQuery(REGISTER_QUERY);

  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: ILogin;
    actions: FormikHelpers<ILogin>;
  }) => {
    try {
      if (!isLoggedIn) {
        const passwordMatch =
          values.password &&
          values["confirm-password"] &&
          values.password === values["confirm-password"];
        if (!passwordMatch) {
          actions.setErrors({
            password: "Las contraseñas no coinciden",
            "confirm-password": "Las contraseñas no coinciden",
          });
          return;
        }
        await createUser({
          variables: {
            createUserInput: {
              name: values.name,
              email: values.email,
              password: values.password,
            },
          },
        });
      } else if (
        isLoggedIn &&
        values.name !== data.getCurrentUser.name &&
        values.email !== data.getCurrentUser.email
      ) {
        await updateUser({
          variables: {
            userInput: {
              name: values.name,
              email: values.email,
            },
          },
        });
      }
    } catch (err) {
      console.log({ err });
      if (err.message === "instance not unique") {
        actions.setErrors({
          email: "Este correo se encuentra asociado a una cuenta",
        });
      }

      throw err;
    }
  };
  const isLoggedIn = !!localStorage.getItem("token");

  if (isLoggedIn && loading && !data) return <Spinner />;
  console.log({ error });
  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={1} steps={5} />
        <Heading as="h1">Acerca tuyo</Heading>
        <p>Necesitamos algunos datos para tu cuenta.</p>
        <Formik
          initialValues={{
            name: data.getCurrentUser?.name ?? "",
            email: data.getCurrentUser?.email ?? "",
            password: "",
            "confirm-password": "",
          }}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
          validationSchema={createProfileSchema}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <InputField
                name="name"
                label="Nombre"
                type="name"
                inputType="input"
              />
              <InputField
                name="email"
                label="Email"
                type="email"
                inputType="input"
              />
              {!isLoggedIn && (
                <Fragment>
                  <InputField
                    name="password"
                    label="Contraseña"
                    type="password"
                    inputType="input"
                    autoComplete="new-password"
                  />
                  <InputField
                    name="confirm-password"
                    label="Confirma tu Contraseña"
                    type="password"
                    inputType="input"
                  />
                </Fragment>
              )}

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Cargando" : "Continuar"}
              </Button>
              {!isLoggedIn && (
                <p sx={{ textAlign: "left" }}>
                  ¿Ya tienes una cuenta?{" "}
                  <Link
                    to="/app/ingresar"
                    sx={{
                      color: "primary",
                      "&:hover": {
                        color: "header",
                      },
                    }}
                  >
                    Ingresa aquí
                  </Link>
                </p>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
