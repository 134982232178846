import { gql } from "@apollo/client";
import { USER_FRAGEMENT } from "../../fragments/userFragment";

export const LOGIN_USER = gql`
  ${USER_FRAGEMENT}
  mutation($loginUserInput: LoginUserInput!) {
    loginUser(data: $loginUserInput) {
      token
      user {
        ...UserFragemnt
      }
    }
  }
`;
