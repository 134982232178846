/**@jsx jsx */
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import React, { Fragment } from "react";
import { Button, Heading, jsx, Spinner, Text } from "theme-ui";
import * as Yup from "yup";

import { InputField } from "../form/input";
import { CheckBox } from "../form/checkbox";
import { BreadCrumbs } from "../navigation/bread-crumbs";
import { Layout } from "../layout/main-layout";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import {
  Experience,
  ExperienceInput,
  MutationCreateExperienceArgs,
  User,
} from "../../apollo/schemas/api";
import { CREATE_EXPERIENCE } from "../../apollo/mutations/experiences/createExperience";
import { GET_EXPERIENCE } from "../../apollo/queries/experiences/getExperience";
import { UPDATE_EXPERIENCE } from "../../apollo/mutations/experiences/updateExperience";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const today = new Date();

const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .max(today, "Debe ser menor a la fecha de hoy")
    .required("Obligatorio"),
  currentJob: Yup.boolean(),
  endDate: Yup.date().when("currentJob", {
    is: false,
    then: (fieldSchema) =>
      fieldSchema
        .max(today, "Debe ser menor a la fecha de hoy")
        .required("Obligatorio"),
  }),
  position: Yup.string()
    .min(2, "Muy corto!")
    .max(50, "Muy largo!")
    .required("Obligatorio"),
  description: Yup.string()
    .min(2, "Muy corto!")
    .max(50, "Muy largo!")
    .required("Obligatorio"),
  referral: Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Obligatorio"),
    name: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
    phone: Yup.string()
      .matches(phoneRegExp, "El número no es válido.")
      .required("Obligatorio"),
    company: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
    position: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
  }),
});

export const UserReferencesEdit: React.FC<{ id: string }> = ({ id }) => {
  const {
    data,
    loading,
  }: { data: { getExperience: Experience }; loading: boolean } = useQuery(
    GET_EXPERIENCE,
    {
      variables: {
        id,
      },
    }
  );
  const [updateExperience] = useMutation(UPDATE_EXPERIENCE, {
    onError: (error) => {
      console.log({ error });
    },
  });
  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );
  const experience = data.getExperience;

  const handleSubmit = async ({
    values,
    actions = null,
  }: {
    values: ExperienceInput;
    actions?: FormikHelpers<ExperienceInput>;
    redirect?: boolean;
  }) => {
    const payload: MutationCreateExperienceArgs = {
      experienceInput: { ...values },
    };
    try {
      const response = await updateExperience({
        variables: {
          ...payload,
          id,
        },
        refetchQueries: [{ query: GET_CURRENT_USER }],
      });

      if (response && response.data) {
        navigate("/app/editar-referencias/");
        actions && actions.resetForm();
      } else {
        console.log({ response });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const initialValues: ExperienceInput = {
    currentJob: experience.currentJob,
    description: experience.description,
    endDate: experience.endDate,
    position: experience.position,
    startDate: experience.startDate,
    referral: {
      company: experience.referral.company,
      email: experience.referral.email,
      name: experience.referral.name,
      phone: experience.referral.phone,
      position: experience.referral.position,
    },
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1">Acerca de tus referencias</Heading>
        <p>Edita tu experiencia laboral.</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <div sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
                <Fragment>
                  <fieldset
                    sx={{ borderColor: "muted", borderStyle: "dashed" }}
                  >
                    <legend sx={{ fontSize: 1 }}>Descripción Laboral</legend>
                    <div
                      sx={{
                        display: "grid",
                        gap: 3,
                      }}
                    >
                      <InputField
                        name={`position`}
                        type={"text"}
                        label={"Puesto"}
                        inputType={"input"}
                      />
                      <InputField
                        name={`startDate`}
                        type={"date"}
                        label={"Desde"}
                        inputType={"input"}
                      />
                      <div sx={{ px: 3, display: "grid", gap: 2 }}>
                        <Text>¿Trabajo Actual?</Text>
                        <CheckBox name={`currentJob`} type="checkbox" />
                      </div>
                      {!values.currentJob && (
                        <InputField
                          name={`endDate`}
                          type={"date"}
                          label={"Hasta"}
                          inputType={"input"}
                        />
                      )}
                      <InputField
                        name={`description`}
                        type={"text"}
                        label={"Descripción"}
                        inputType={"textarea"}
                      />
                    </div>
                  </fieldset>
                  <fieldset
                    sx={{
                      borderColor: "muted",
                      borderStyle: "dashed",
                      display: "grid",
                      gap: 3,
                    }}
                  >
                    <legend sx={{ fontSize: 1 }}>Referencia Laboral</legend>

                    <InputField
                      name={`referral.company`}
                      type={"text"}
                      label={"Empresa"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.name`}
                      type={"text"}
                      label={"Nombre de Referencia"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.phone`}
                      type={"tel"}
                      label={"Teléfono de Referencia"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.email`}
                      type={"email"}
                      label={"Email de Referencia"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.position`}
                      type={"text"}
                      label={"Cargo"}
                      inputType={"input"}
                    />
                  </fieldset>
                </Fragment>
              </div>
              <Button
                type="button"
                sx={{
                  width: "100%",
                }}
                onClick={() => handleSubmit({ values, redirect: true })}
              >
                Finalizar
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
