/**@jsx jsx */
import { Form, Formik } from "formik";
import { Button, Heading, jsx } from "theme-ui";
import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";
import { Range } from "../../form/range";
import {
  EnglishLevelInput,
  JobInput,
  MutationUpdateJobArgs,
  SoftSkillsInput,
  UserInput,
} from "../../../apollo/schemas/api";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../../apollo/mutations/users/updateUser";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../../apollo/queries/users/getCurrentUser";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";

const softSkills = [
  {
    name: "trabajoEquipo",
    label: "Trabajo en Equipo",
    parent: "softSkills",
  },
  {
    name: "liderazgo",
    label: "Liderazgo",
    parent: "softSkills",
  },
  {
    name: "comunicacionEfectiva",
    label: "Comunicación Efectiva",
    parent: "softSkills",
  },
  {
    name: "escuchaActiva",
    label: "Escucha Activa",
    parent: "softSkills",
  },
  {
    name: "adaptabilidad",
    label: "Adaptabilidad",
    parent: "softSkills",
  },
  {
    name: "creatividad",
    label: "Creatividad",
    parent: "softSkills",
  },
  {
    name: "espirituServicio",
    label: "Espíritu de Servicio",
    parent: "softSkills",
  },
  {
    name: "comunicacion",
    label: "Comunicación",
    parent: "softSkills",
  },
  {
    name: "empatia",
    label: "Empatía",
    parent: "softSkills",
  },
  {
    name: "proactividad",
    label: "Proactividad",
    parent: "softSkills",
  },
  {
    name: "gestionConflicto",
    label: "Gestión de Conflictos",
    parent: "softSkills",
  },
  {
    name: "gestionCambio",
    label: "Gestión de Cambio",
    parent: "softSkills",
  },
];

const englishSkills = [
  {
    name: "speaking",
    label: "Fluidez",
    parent: "englishLevel",
  },
  {
    name: "reading",
    label: "Lectura",
    parent: "englishLevel",
  },
  {
    name: "writing",
    label: "Escritura",
    parent: "englishLevel",
  },
];

interface IValues {
  softSkills: {
    trabajoEquipo: number;
    liderazgo: number;
    comunicacionEfectiva: number;
    escuchaActiva: number;
    adaptabilidad: number;
    creatividad: number;
    espirituServicio: number;
    comunicacion: number;
    empatia: number;
    proactividad: number;
    gestionConflicto: number;
    gestionCambio: number;
  };
  englishLevel: {
    speaking: number;
    writing: number;
    reading: number;
  };
}

export const JobAbilities = () => {
  const initialValues = {
    softSkills: {
      trabajoEquipo: 1,
      liderazgo: 1,
      comunicacionEfectiva: 1,
      escuchaActiva: 1,
      adaptabilidad: 1,
      creatividad: 1,
      espirituServicio: 1,
      comunicacion: 1,
      empatia: 1,
      proactividad: 1,
      gestionConflicto: 1,
      gestionCambio: 1,
    },
    englishLevel: {
      speaking: 0,
      writing: 0,
      reading: 0,
    },
  };

  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => {
      navigate("/app/crear-empleo/evaluacion");
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleSubmit = async ({ values }: { values: IValues }) => {
    const id = localStorage.getItem("jobid");
    const softSkills: SoftSkillsInput = { ...values.softSkills };
    const englishLevel: EnglishLevelInput = { ...values.englishLevel };
    const jobInput: JobInput = {
      softSkills,
      englishLevel,
    };
    const input: MutationUpdateJobArgs = {
      id,
      jobInput: jobInput,
    };
    await updateJob({
      variables: {
        ...input,
      },
    });
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={5} steps={6} />
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Crea una nueva oportunidad
        </Heading>
        <p>
          Por favor selecciona el puntaje para las habilidades del postulante.
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit({ values })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Nivel de Inglés</legend>
                <div>
                  {englishSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Habilidades blandas:</legend>
                <div>
                  {softSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Cargando..." : "Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
