import { gql } from "@apollo/client";
import { USER_FRAGEMENT } from "../../fragments/userFragment";

export const GET_ALL_USERS = gql`
  ${USER_FRAGEMENT}
  query {
    getUsers {
      ...UserFragemnt
    }
  }
`;
