import { gql } from "@apollo/client";

export const GET_EXPERIENCE = gql`
  query($id: String!) {
    getExperience(id: $id) {
      _id
      position
      startDate
      endDate
      currentJob
      referral {
        name
        phone
        email
        position
        company
      }
      description
    }
  }
`;
