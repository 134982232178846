import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "../../fragments/jobFragment";

export const CREATE_JOB = gql`
  ${JOB_FRAGMENT}
  mutation($createJobInput: CreateJobInput!) {
    createJob(createJobInput: $createJobInput) {
      ...JobFragment
    }
  }
`;
