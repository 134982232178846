/**@jsx jsx */
import React from "react";
import { Button, Heading, jsx } from "theme-ui";
import { Layout } from "../layout/main-layout";
import { User } from "../../apollo/schemas/api";
import { navigate } from "gatsby";

export const UserReferencesList: React.FC<{ user: User }> = ({ user }) => {
  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Acerca de tus referencias
        </Heading>
        <p>Edita o agrega referencias.</p>
        {user.experiences && user.experiences.length > 0 && (
          <ul sx={{ listStyleType: "none", p: 0, m: 0, mb: 4 }}>
            {user.experiences.map((item, index) => (
              <li
                sx={{
                  bg: "primary",
                  px: 2,
                  py: 3,
                  color: "background",
                  mt: 2,
                  borderRadius: "12px",
                  fontSize: 2,
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
                key={index}
                onClick={() => {
                  navigate(`/app/editar-referencias/${item._id}`);
                }}
              >
                {item.referral.company}
              </li>
            ))}
          </ul>
        )}
        <Button
          sx={{
            bg: "muted",
            color: "header",
            ":hover": {
              bg: "header",
              color: "background",
            },
          }}
          onClick={() => navigate("/app/agregar-referencias/")}
        >
          Agregar Referencia
        </Button>
      </div>
    </Layout>
  );
};
