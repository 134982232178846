/**@jsx jsx */
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import { Link, navigate } from "gatsby";
import React from "react";
import { Button, Heading, jsx, Text } from "theme-ui";
import { client } from "../../apollo/client";
import { LOGIN_USER } from "../../apollo/mutations/auth/loginUser";
import { InputField } from "../form/input";
import { Layout } from "../layout/main-layout";

interface ILogin {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    onCompleted: async (data) => {
      const { token } = data.loginUser;

      localStorage.setItem("token", token);
      await client.resetStore();
      navigate("/app/");
    },
    onError: (error) => {
      console.log({ error });
    },
  });
  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: ILogin;
    actions: FormikHelpers<ILogin>;
  }) => {
    try {
      await loginUser({
        variables: {
          loginUserInput: {
            email: values.email,
            password: values.password,
          },
        },
      });
    } catch (err) {
      console.log({ err });
      actions.setErrors({
        email: "Verifica que tus datos sean correctos",
        password: "Verifica que tus datos sean correctos",
      });
      throw err;
    }
  };

  return (
    <Layout>
      <div sx={{ pt: 5, maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1">Ingresa</Heading>
        <p>Por favor, completa los campos con tu correo y constraseña.</p>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                py: [3, 5],
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <InputField
                name="email"
                label="Email"
                type="email"
                inputType="input"
              />
              <InputField
                name="password"
                label="Contraseña"
                type="password"
                inputType="input"
              />
              <Text sx={{ textAlign: "right" }}>
                <Link
                  to="/app/recuperar-password"
                  sx={{
                    color: "primary",
                    "&:hover": {
                      color: "header",
                    },
                  }}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </Text>
              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting || loading}
                sx={{
                  width: "100%",
                  bg: (t) => (isSubmitting || loading) && t.colors.blue[0],
                }}
              >
                {isSubmitting || loading ? "Ingresando" : "Ingresar"}
              </Button>
              <p sx={{ textAlign: "left" }}>
                ¿Aún no tienes una cuenta?{" "}
                <Link
                  to="/app/registrarme"
                  sx={{
                    color: "primary",
                    "&:hover": {
                      color: "header",
                    },
                  }}
                >
                  Regístrate
                </Link>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
