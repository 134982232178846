/**@jsx jsx */
import { useMutation } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import { navigate } from "gatsby-link";
import React from "react";

import { Button, Heading, jsx } from "theme-ui";
import { PASSWORD_RESET_CODE } from "../../apollo/mutations/auth/usePasswordCode";
import { client } from "../../apollo/client";

import { InputField } from "../form/input";
import { Layout } from "../layout/main-layout";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";

interface ILogin {
  code: string;
}

export const UsePasswordCode: React.FC = () => {
  const [passwordResetCode] = useMutation(PASSWORD_RESET_CODE, {
    onCompleted: async (data) => {
      const { token } = data.usePasswordResetCode;

      localStorage.setItem("token", token);
      await client.resetStore();
      navigate("/app/");
    },
    onError: (error) => {
      console.log({ error });
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    awaitRefetchQueries: true,
  });
  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: ILogin;
    actions: FormikHelpers<ILogin>;
  }) => {
    try {
      const response = await passwordResetCode({
        variables: {
          code: values.code,
        },
      });
    } catch (err) {
      actions.setErrors({
        code: "Código no válido o expiró intenta de nuevo",
      });
      throw err;
    }
  };

  return (
    <Layout>
      <div sx={{ pt: 5, maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1">Confirma tu contraseña</Heading>
        <p>
          Ingresa el código enviado a tu correo, junto a tu nueva contraseña.
        </p>
        <Formik
          initialValues={{
            code: "",
          }}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{ pt: 5, display: "flex", gap: 4, flexDirection: "column" }}
            >
              <InputField
                name="code"
                label="Código"
                type="text"
                inputType="input"
              />

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Confirmando código" : "Confirmar código"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
