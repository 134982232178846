/**@jsx jsx */
import { useMutation, useQuery } from "@apollo/client";
import { Form, Formik, FormikHelpers, useField } from "formik";
import { navigate } from "gatsby";
import { InputHTMLAttributes } from "react";
import { Button, Heading, Input, jsx, Label, Spinner } from "theme-ui";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import { GET_JOB } from "../../../apollo/queries/jobs/getJob";
import { GET_CURRENT_USER } from "../../../apollo/queries/users/getCurrentUser";
import {
  Job,
  JobInput,
  MutationUpdateJobArgs,
  UserInput,
} from "../../../apollo/schemas/api";

import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";

interface FieldsInterface {
  value: string;
  name: string;
}

type CheckboxType = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  type: string;
};

const Checkbox = ({ label, ...props }: CheckboxType) => {
  const [field] = useField(props);
  return (
    <Label
      sx={{
        bg: field.checked ? "header" : "muted",
        p: 2,
        color: "background",
        width: "max-content",
        borderRadius: "8px",
        transition: "ease-in-out",
        transitionDuration: "0.3s",
      }}
    >
      <Input {...field} {...props} id={field.name} sx={{ display: "none" }} />
      {label}
    </Label>
  );
};

const fields: FieldsInterface[] = [
  { value: "TELECOMUNICACIONES", name: "TELECOMUNICACIONES" },
  { value: "CONSUMO_MASIVO", name: "CONSUMO MASIVO" },
  { value: "INDUSTRIAL", name: "INDUSTRIAL" },
  { value: "CERAMICO", name: "CERAMICO" },
  { value: "MINERIA", name: "MINERIA" },
  { value: "EDUCACION", name: "EDUCACION" },
  { value: "AEROPORTUARIO", name: "AEROPORTUARIO" },
  { value: "AGROINDUSTRIAL", name: "AGROINDUSTRIAL" },
  { value: "CONSTRUCCION", name: "CONSTRUCCION" },
  { value: "INMOBILIARIA", name: "INMOBILIARIA" },
  { value: "HIDROCARBUROS", name: "HIDROCARBUROS" },
  { value: "INDUSTRIA", name: "INDUSTRIA" },
  { value: "QUIMICA", name: "QUIMICA" },
  { value: "RETAIL", name: "RETAIL" },
  { value: "TEXTIL", name: "TEXTIL" },
  { value: "PLASTICOS", name: "PLASTICOS" },
  { value: "ENERGIA", name: "ENERGIA" },
  { value: "COMERCIALIZACION", name: "COMERCIALIZACION" },
  { value: "OIL_GAS", name: "OIL GAS" },
  { value: "BANCA", name: "BANCA" },
  { value: "MANUFACTURA", name: "MANUFACTURA" },
  { value: "AUTOMOTRIZ", name: "AUTOMOTRIZ" },
  { value: "PETROLERA", name: "PETROLERA" },
];

export const EditJobFields = ({ jobId }: { jobId: string }) => {
  const [updateJob] = useMutation(UPDATE_JOB, {
    onError: (err) => {
      console.log({ err });
    },
    onCompleted: () => {
      navigate(`/app/${jobId}/`);
    },
  });

  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: { fields: string[] };
    actions: FormikHelpers<{ fields: string[] }>;
  }) => {
    const jobInput: JobInput = {
      fields: values.fields,
    };
    const input: MutationUpdateJobArgs = {
      id: jobId,
      jobInput,
    };
    await updateJob({
      variables: {
        ...input,
      },
    });
  };

  const { data, loading } = useQuery<{ getJob: Job }>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });

  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );

  const job = data.getJob;

  const initialValues: { fields: string[] } = {
    fields: job.fields ?? [],
  };
  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1"> Edita {job.title}</Heading>
        <p>Marca los rubros donde los postulantes deben tener experiencia.</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <div
                role="group"
                aria-labelledby="checkbox-group"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  gap: 2,
                  p: 3,
                }}
              >
                {fields.map((field: FieldsInterface) => (
                  <Checkbox
                    name={"fields"}
                    type="checkbox"
                    label={field.name}
                    value={field.value}
                    key={field.name}
                  />
                ))}
              </div>

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
