/**@jsx jsx */
import { useMutation } from "@apollo/client";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { navigate } from "gatsby";
import { InputHTMLAttributes, useState } from "react";
import { Button, Heading, Input, jsx, Label } from "theme-ui";
import { UPDATE_USER } from "../../apollo/mutations/users/updateUser";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import { UserInput } from "../../apollo/schemas/api";

import { Layout } from "../layout/main-layout";
import { BreadCrumbs } from "../navigation/bread-crumbs";

interface FieldsInterface {
  value: string;
  name: string;
}

type CheckboxType = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  type: string;
};

const Checkbox = ({ label, ...props }: CheckboxType) => {
  const [field] = useField(props);
  return (
    <Label
      sx={{
        bg: field.checked ? "header" : "muted",
        p: 2,
        color: field.checked ? "background" : "text",
        width: "max-content",
        borderRadius: "8px",
        transition: "ease-in-out",
        transitionDuration: "0.3s",
      }}
    >
      <Input {...field} {...props} id={field.name} sx={{ display: "none" }} />
      {label}
    </Label>
  );
};

const fields: FieldsInterface[] = [
  { value: "TELECOMUNICACIONES", name: "TELECOMUNICACIONES" },
  { value: "CONSUMO_MASIVO", name: "CONSUMO MASIVO" },
  { value: "INDUSTRIAL", name: "INDUSTRIAL" },
  { value: "CERAMICO", name: "CERAMICO" },
  { value: "MINERIA", name: "MINERIA" },
  { value: "EDUCACION", name: "EDUCACION" },
  { value: "AEROPORTUARIO", name: "AEROPORTUARIO" },
  { value: "AGROINDUSTRIAL", name: "AGROINDUSTRIAL" },
  { value: "CONSTRUCCION", name: "CONSTRUCCION" },
  { value: "INMOBILIARIA", name: "INMOBILIARIA" },
  { value: "HIDROCARBUROS", name: "HIDROCARBUROS" },
  { value: "INDUSTRIA", name: "INDUSTRIA" },
  { value: "QUIMICA", name: "QUIMICA" },
  { value: "RETAIL", name: "RETAIL" },
  { value: "TEXTIL", name: "TEXTIL" },
  { value: "PLASTICOS", name: "PLASTICOS" },
  { value: "ENERGIA", name: "ENERGIA" },
  { value: "COMERCIALIZACION", name: "COMERCIALIZACION" },
  { value: "OIL_GAS", name: "OIL GAS" },
  { value: "BANCA", name: "BANCA" },
  { value: "MANUFACTURA", name: "MANUFACTURA" },
  { value: "AUTOMOTRIZ", name: "AUTOMOTRIZ" },
  { value: "PETROLERA", name: "PETROLERA" },
];

const schemaValidation = Yup.object().shape({
  fields: Yup.array()
    .of(Yup.string().required("Requerido"))
    .min(1, "Necesitas agregar un rubro")
    .required("Requerido"),
});

export const UserFields = () => {
  const [updateUser] = useMutation(UPDATE_USER, {
    onError: (err) => {
      console.log({ err });
    },
    onCompleted: () => {
      navigate("/app/registrarme/habilidades");
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const userInput: UserInput = {
      fields: localFields.map((item) => item.value),
      onboarding: {
        finished: false,
        link: "/app/registrarme/habilidades",
      },
    };
    await updateUser({
      variables: {
        userInput,
      },
    });
  };
  const [selectedField, setSelectedField] = useState("");
  const [localFields, setLocalFields] = useState<FieldsInterface[]>([]);
  const [isSelectOpen, setSelectOpen] = useState(false);
  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={4} steps={6} />
        <Heading as="h1">Acerca de tu experiencia</Heading>
        <p>Marca los rubros donde tienes experiencia.</p>
        <form
          sx={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
            position: "relative",
          }}
          onSubmit={handleSubmit}
        >
          <input
            id="rubros"
            type="text"
            name="rubros"
            value={selectedField}
            onChange={(e) => {
              setSelectedField(e.target.value.toUpperCase());
              if (!isSelectOpen) {
                setSelectOpen(true);
              }
            }}
            onClick={() => {
              if (!isSelectOpen) {
                setSelectOpen(true);
              }
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "inset 0px 0.5px 4px rgba(96, 97, 112, 0.32)",
              borderRadius: ["16px", "24px"],
              height: "56px",
              overflow: "hidden",
              px: "16px",
              fontSize: 2,
              zIndex: 100,
            }}
          />
          <div
            sx={{
              display: isSelectOpen ? "block" : "none",
              position: "absolute",
              width: "100%",
              height: "200px",
              bg: "background",
              top: "56px",
              overflowY: "scroll",
              zIndex: 100,
            }}
          >
            <ul sx={{ listStyle: "none" }}>
              {selectedField && (
                <li
                  sx={{
                    p: 1,
                    borderBottom: "1px solid #eee",
                    cursor: "pointer",
                    "&:hover": {
                      bg: "muted",
                    },
                  }}
                  onClick={() => {
                    setLocalFields((prev) => [
                      ...prev,
                      { name: selectedField, value: selectedField },
                    ]);
                    setSelectOpen(false);
                  }}
                >
                  Agregar: {selectedField}
                </li>
              )}
              {fields
                .filter((item) => {
                  return (
                    item.name.toUpperCase().includes(selectedField) &&
                    !localFields.map((item) => item.name).includes(item.name)
                  );
                })
                .map((item) => (
                  <li
                    sx={{
                      p: 1,
                      borderBottom: "1px solid #eee",
                      cursor: "pointer",
                      "&:hover": {
                        bg: "muted",
                      },
                    }}
                    key={item.value}
                    onClick={() => {
                      setLocalFields((p) => [...p, item]);
                      setSelectOpen(false);
                    }}
                  >
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
          <div
            sx={{
              width: "100vw",
              height: "100vh",
              top: 0,
              position: "fixed",
              display: isSelectOpen ? "block" : "none",
              left: 0,
              zIndex: 1,
            }}
            onClick={() => setSelectOpen(false)}
          ></div>
          <div>
            {localFields.map((item, i) => (
              <button
                key={i}
                sx={{
                  border: 0,
                  bg: "secondary",
                  p: 2,
                  mr: 2,
                  borderRadius: 3,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setLocalFields((prev) =>
                    prev.filter((field) => field.name !== item.name)
                  );
                }}
                type="button"
              >
                {item.name} <span>X</span>
              </button>
            ))}
          </div>
          <Button
            aria-label="submit"
            type="submit"
            sx={{
              width: "100%",
            }}
          >
            Continuar
          </Button>
        </form>
      </div>
    </Layout>
  );
};
