/**@jsx jsx */
import { useMutation } from "@apollo/client";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import { navigate } from "gatsby-link";
import { Helmet } from "react-helmet";
import { Text, Heading, jsx, Button, Spinner } from "theme-ui";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import {
  ModuleInput,
  MutationUpdateJobArgs,
  User,
} from "../../../apollo/schemas/api";
import { CheckBox } from "../../form/checkbox";
import { InputField } from "../../form/input";
import { SelectField } from "../../form/select";
import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";

const modulesArray = [
  { id: 2, value: "CO", name: "CO" },
  { id: 3, value: "FI", name: "FI" },
  { id: 4, value: "HCM", name: "HCM" },
  { id: 5, value: "MM", name: "MM" },
  { id: 6, value: "SD", name: "SD" },
  { id: 7, value: "PP", name: "PP" },
  { id: 8, value: "PM", name: "PM" },
  { id: 9, value: "PS", name: "PS" },
  { id: 10, value: "ABAP", name: "ABAP" },
  { id: 11, value: "WM", name: "WM" },
  { id: 12, value: "CS", name: "CS" },
  { id: 13, value: "BI", name: "BI" },
  { id: 14, value: "B1", name: "B1" },
  { id: 15, value: "SCM", name: "SCM" },
  { id: 16, value: "CRM", name: "CRM" },
  { id: 17, value: "SDK", name: "SDK" },
  { id: 18, value: "BASIS", name: "BASIS" },
];

declare global {
  interface Window {
    cloudinary: any;
  }
}

interface IValues {
  module: string;
  experience: string;
  certified: boolean;
}

export const JobSapModule = () => {
  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => {
      navigate("/app/crear-empleo/rubros");
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const handSubmitForm = async ({
    values,
    actions,
  }: {
    values: { modules: IValues[] };
    actions: FormikHelpers<{ modules: IValues[] }>;
  }) => {
    const id = localStorage.getItem("jobid");
    const moduleInput: ModuleInput[] = values.modules.map((module) => ({
      certified: module.certified,
      module: module.module,
      experience: module.experience.toString(),
    }));
    const input: MutationUpdateJobArgs = {
      id,
      jobInput: {
        modules: moduleInput,
      },
    };
    await updateJob({
      variables: {
        ...input,
      },
    });
  };

  return (
    <Layout>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <div
        sx={{
          py: [3, 5],
          maxWidth: 512,
          mx: "auto",
          width: "90vw",
        }}
      >
        <BreadCrumbs step={3} steps={6} />
        <Heading as="h1">Experiencia SAP</Heading>
        <p>Selecciona los módulos a evaluar para el postulante.</p>
        <Formik
          initialValues={{
            modules: [
              {
                module: "",
                experience: "",
                certified: false,
              },
            ],
          }}
          onSubmit={(values, actions) => handSubmitForm({ values, actions })}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
                <FieldArray
                  name="modules"
                  render={({ remove, push }) => (
                    <div
                      sx={{ display: "flex", gap: 3, flexDirection: "column" }}
                    >
                      {values.modules.map((_, i) => (
                        <fieldset
                          key={i}
                          sx={{
                            display: "grid",
                            gap: 3,
                            borderColor: "muted",
                            borderStyle: "dashed",
                            gridTemplateColumns: ["1fr 1fr"],
                          }}
                        >
                          <legend>Módulo SAP</legend>
                          <SelectField
                            name={`modules[${i}].module`}
                            options={modulesArray}
                            label={"Modulo"}
                          />
                          <InputField
                            name={`modules[${i}].experience`}
                            type={"number"}
                            label={"Experiencia"}
                            inputType={"input"}
                          />
                          <div
                            sx={{ display: "grid", gap: 2, gridColumn: "1/3" }}
                          >
                            <Text>¿Necesitan estar certificados?</Text>
                            <CheckBox
                              name={`modules[${i}].certified`}
                              type="checkbox"
                            />
                          </div>
                          {values.modules.length >= 2 && (
                            <Button
                              type="button"
                              onClick={() => {
                                remove(i);
                              }}
                              sx={{
                                bg: "transparent",
                                color: "muted",
                                gridColumn: "1/3",
                                cursor: "pointer",
                                ":hover": {
                                  bg: "transparent",
                                  color: "primary",
                                },
                              }}
                            >
                              - Eliminar Modulo -
                            </Button>
                          )}{" "}
                        </fieldset>
                      ))}
                      <Button
                        type="button"
                        onClick={() => {
                          push({
                            module: "",
                            experience: "",
                            certified: false,
                          });
                        }}
                        sx={{
                          bg: "muted",
                          color: "header",
                          ":hover": {
                            bg: "header",
                            color: "background",
                          },
                        }}
                      >
                        + Agregar Modulo +
                      </Button>
                    </div>
                  )}
                />
                <input
                  aria-label="submit"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    variant: "buttons.primary",
                    border: "none",
                    outline: "none",
                  }}
                  value={isSubmitting ? "Cargando..." : "Continuar"}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};
