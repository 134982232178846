/**@jsx jsx */
import { Form, Formik } from "formik";
import { Button, Heading, jsx, Spinner } from "theme-ui";
import { Layout } from "../layout/main-layout";
import { BreadCrumbs } from "../navigation/bread-crumbs";
import { Range } from "../form/range";
import {
  EnglishLevelInput,
  SoftSkillsInput,
  User,
  UserInput,
} from "../../apollo/schemas/api";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_USER } from "../../apollo/mutations/users/updateUser";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";

const softSkills = [
  {
    name: "trabajoEquipo",
    label: "Trabajo en Equipo",
    parent: "softSkills",
  },
  {
    name: "liderazgo",
    label: "Liderazgo",
    parent: "softSkills",
  },
  {
    name: "comunicacionEfectiva",
    label: "Comunicación Efectiva",
    parent: "softSkills",
  },
  {
    name: "escuchaActiva",
    label: "Escucha Activa",
    parent: "softSkills",
  },
  {
    name: "adaptabilidad",
    label: "Adaptabilidad",
    parent: "softSkills",
  },
  {
    name: "creatividad",
    label: "Creatividad",
    parent: "softSkills",
  },
  {
    name: "espirituServicio",
    label: "Espíritu de Servicio",
    parent: "softSkills",
  },
  {
    name: "comunicacion",
    label: "Comunicación",
    parent: "softSkills",
  },
  {
    name: "empatia",
    label: "Empatía",
    parent: "softSkills",
  },
  {
    name: "proactividad",
    label: "Proactividad",
    parent: "softSkills",
  },
  {
    name: "gestionConflicto",
    label: "Gestión de Conflictos",
    parent: "softSkills",
  },
  {
    name: "gestionCambio",
    label: "Gestión de Cambio",
    parent: "softSkills",
  },
];

const englishSkills = [
  {
    name: "speaking",
    label: "Fluidez",
    parent: "englishLevel",
  },
  {
    name: "reading",
    label: "Lectura",
    parent: "englishLevel",
  },
  {
    name: "writing",
    label: "Escritura",
    parent: "englishLevel",
  },
];

interface IValues {
  softSkills: {
    trabajoEquipo: number;
    liderazgo: number;
    comunicacionEfectiva: number;
    escuchaActiva: number;
    adaptabilidad: number;
    creatividad: number;
    espirituServicio: number;
    comunicacion: number;
    empatia: number;
    proactividad: number;
    gestionConflicto: number;
    gestionCambio: number;
  };
  englishLevel: {
    speaking: number;
    writing: number;
    reading: number;
  };
}

export const UserAbilitiesEdit = () => {
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      navigate("/app/editar/");
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleSubmit = async ({ values }: { values: IValues }) => {
    const softSkills: SoftSkillsInput = { ...values.softSkills };
    const englishLevel: EnglishLevelInput = { ...values.englishLevel };
    const userInput: UserInput = {
      softSkills,
      englishLevel,
    };
    await updateUser({
      variables: {
        userInput: {
          ...userInput,
        },
      },
    });
  };

  const {
    data,
    loading,
  }: { data: { getCurrentUser: User }; loading: boolean } = useQuery(
    GET_CURRENT_USER
  );

  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );
  const user = data.getCurrentUser;
  const initialValues = {
    softSkills: {
      trabajoEquipo: user.softSkills?.trabajoEquipo ?? 3,
      liderazgo: user.softSkills?.liderazgo ?? 3,
      comunicacionEfectiva: user.softSkills?.comunicacionEfectiva ?? 3,
      escuchaActiva: user.softSkills?.escuchaActiva ?? 3,
      adaptabilidad: user.softSkills?.adaptabilidad ?? 3,
      creatividad: user.softSkills?.creatividad ?? 3,
      espirituServicio: user.softSkills?.espirituServicio ?? 3,
      comunicacion: user.softSkills?.comunicacion ?? 3,
      empatia: user.softSkills?.empatia ?? 3,
      proactividad: user.softSkills?.proactividad ?? 3,
      gestionConflicto: user.softSkills?.gestionConflicto ?? 3,
      gestionCambio: user.softSkills?.gestionCambio ?? 3,
    },
    englishLevel: {
      speaking: user.englishLevel?.speaking ?? 0,
      writing: user.englishLevel?.writing ?? 0,
      reading: user.englishLevel?.reading ?? 0,
    },
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Acerca de tus habilidades
        </Heading>
        <p>
          Por favor selecciona el puntaje para tus habilidades. Marca 1 si es
          básico o 5 si es avanzado.
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit({ values })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Nivel de Inglés</legend>
                <div>
                  {englishSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Habilidades blandas</legend>
                <div>
                  {softSkills.map((skill) => (
                    <Range
                      label={skill.label}
                      name={`${skill.parent}.${skill.name}`}
                      key={skill.name}
                      parent={skill.parent}
                    />
                  ))}
                </div>
              </fieldset>

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
