/**@jsx jsx */
import { Form, Formik } from "formik";
import { Button, Heading, jsx, Spinner, Text } from "theme-ui";
import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";
import {
  EvalParamsInput,
  Job,
  JobInput,
  MutationUpdateJobArgs,
} from "../../../apollo/schemas/api";
import { useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../../apollo/queries/users/getCurrentUser";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import { CheckBox } from "../../form/checkbox";
import { InputField } from "../../form/input";
import { GET_JOB } from "../../../apollo/queries/jobs/getJob";

export const EditJobEvaluation = ({ jobId }: { jobId: string }) => {
  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => {
      navigate(`/app/${jobId}/`);
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleSubmit = async ({ values }: { values: EvalParamsInput }) => {
    const jobInput: JobInput = {
      params: values,
    };
    const input: MutationUpdateJobArgs = {
      id: jobId,
      jobInput: jobInput,
    };
    await updateJob({
      variables: {
        ...input,
      },
    });
  };
  const { data, loading } = useQuery<{ getJob: Job }>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });

  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );

  const job = data.getJob;

  const initialValues: EvalParamsInput = {
    moduleCertified: job.params?.moduleCertified ?? false,
    moduleCertificateValue: job.params?.moduleCertificateValue ?? 100,
    module: job.params?.module ?? 19,
    englishLevel: job.params?.englishLevel ?? 19,
    field: job.params?.field ?? 19,
    salary: job.params?.salary ?? 19,
    softSkills: job.params?.softSkills ?? 19,
    availability: job.params?.availability ?? 5,
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Edita {job.title}
        </Heading>
        <p>
          Por favor selecciona el puntaje para las habilidades del postulante.
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit({ values })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset
                sx={{
                  borderColor: "muted",
                  borderStyle: "dashed",
                  display: "grid",
                  gap: 3,
                }}
              >
                <legend sx={{ fontSize: 1 }}>Experiencia por módulo</legend>
                <div sx={{ px: 3, display: "grid", gap: 3 }}>
                  <Text>¿Necesitan estar certificados?</Text>
                  <CheckBox name={"moduleCertified"} type="checkbox" />
                </div>
                <InputField
                  name={"moduleCertificateValue"}
                  type={"number"}
                  label={"Valor del Certificado"}
                  inputType={"input"}
                />
              </fieldset>
              <fieldset
                sx={{
                  borderColor: "muted",
                  borderStyle: "dashed",
                  display: "grid",
                  gap: 3,
                  gridTemplateColumns: [null, "1fr 1fr"],
                }}
              >
                <legend sx={{ fontSize: 1 }}>Evaluación integral</legend>
                <InputField
                  name={"module"}
                  type={"number"}
                  label={"Módulos"}
                  inputType={"input"}
                />
                <InputField
                  name={"field"}
                  type={"number"}
                  label={"Rubros"}
                  inputType={"input"}
                />
                <InputField
                  name={"salary"}
                  type={"number"}
                  label={"Expectativa salarial"}
                  inputType={"input"}
                />
                <InputField
                  name={"softSkills"}
                  type={"number"}
                  label={"Habilidades blandas"}
                  inputType={"input"}
                />
                <InputField
                  name={"englishLevel"}
                  type={"number"}
                  label={"Nivel de inglés"}
                  inputType={"input"}
                />
                <InputField
                  name={"availability"}
                  type={"number"}
                  label={"Disponibilidad"}
                  inputType={"input"}
                />
              </fieldset>

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
