/**@jsx jsx */
import { jsx } from "theme-ui";

const BreadCrumb: React.FC<{
  done: boolean;
}> = ({ done }) => {
  return (
    <div
      sx={{
        width: "30px",
        height: "30px",
        bg: "white",
        borderRadius: "100%",
        display: "grid",
        placeContent: "center",
      }}
    >
      <div
        sx={{
          width: "20px",
          height: "20px",
          border: (t) => `2px solid ${t.colors.blue[2]}`,
          borderRadius: "100%",
          display: "grid",
          placeContent: "center",
        }}
      >
        {done && (
          <div
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "100%",
              bg: "header",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export const BreadCrumbs: React.FC<{
  steps: number;
  step: number;
}> = ({ steps, step }) => {
  const breads = Array.from({ length: steps }, (e, i) => {
    return <BreadCrumb done={i + 1 <= step} key={i} />;
  });
  return (
    <div
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        mb: [3, 5],
      }}
    >
      {breads}
      <div
        sx={{
          height: "2px",
          bg: "header",
          width: "100%",
          position: "absolute",
          left: 0,
          top: "calc(50% - 1px)",
          zIndex: -1,
        }}
      ></div>
    </div>
  );
};
