/**@jsx jsx */
import { Link, navigate } from "gatsby";
import { Fragment, useState } from "react";
import { Button, Heading, jsx, Spinner, Text } from "theme-ui";
import { format } from "date-fns";
import { useMutation, useQuery } from "@apollo/client";

import {
  Job,
  MutationUpdateJobArgs,
  Status,
  // Status,
  User,
} from "../../../apollo/schemas/api";
import { Atom, Building, Calendar, Dollar, Time, Map } from "../../../icons";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import { GET_ALL_JOBS } from "../../../apollo/queries/jobs/getAllJobs";
import { GET_JOB } from "../../../apollo/mutations/jobs/getJob";
import { DELETE_JOB } from "../../../apollo/mutations/jobs/deleteJob";

const JobDescription = ({ job, user }: { job: Job; user: User }) => {
  const date = format(new Date(job.updatedAt["@ts"]), "dd/MM/yyyy");
  const [deleteJob] = useMutation(DELETE_JOB, {
    onCompleted: () => navigate("/app"),
  });
  const handleDelete = (id) => {
    deleteJob({
      variables: {
        id,
      },
      refetchQueries: [{ query: GET_ALL_JOBS }],
    });
  };
  return (
    <div
      sx={{ display: "grid", gridTemplateColumns: [null, "1fr 256px"], gap: 3 }}
    >
      <div sx={{ display: "grid", gap: 3 }}>
        <div>
          <Heading as="h2" sx={{ textTransform: "capitalize" }}>
            {job.title}
          </Heading>
        </div>
        <Text as="h4">
          <b>Puesto:</b> {job.position}
        </Text>
        <div>
          <Text as="h4">
            <b>Description:</b>
          </Text>
          <Text>{job.description}</Text>
        </div>
        <Text as="h4">
          <div dangerouslySetInnerHTML={{ __html: job.content }} />
        </Text>
      </div>
      <div
        sx={{
          bg: "background",
          borderRadius: "16px",
          boxShadow: "0 0 10px rgba(32, 99, 244, 0.2)",
          p: 2,
          height: "max-content",
          display: "grid",
        }}
      >
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "24px 1fr",
            gridAutoRows: "max-content",
            alignItems: "center",
            gap: 2,
            py: 2,
            borderBottom: (t) => `1px solid ${t.colors.light[2]}`,
          }}
        >
          <span sx={{ color: "header" }}>
            <Calendar />
          </span>
          <span sx={{ fontSize: 0 }}>Publicado el {date}</span>
          <span sx={{ color: "header" }}>
            <Map />
          </span>
          <span sx={{ fontSize: 0 }}>
            {job.address?.city ?? "Lima"} - {job.address?.country ?? "Perú"}
          </span>
        </div>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "24px 1fr",
            gridAutoRows: "max-content",
            alignItems: "center",
            gap: 2,
            py: 2,
          }}
        >
          <span sx={{ color: "primary" }}>
            <Time />
          </span>
          <span sx={{ fontSize: 0 }}>
            {job.type === "PART" ? "Part-time" : "Full-time"}
          </span>
          <span sx={{ color: "primary" }}>
            <Dollar />
          </span>
          <span sx={{ fontSize: 0 }}>
            {job.salaryRange ?? "No especificado"}
          </span>
          <span sx={{ color: "primary" }}>
            <Atom />
          </span>
          <span sx={{ fontSize: 0 }}>
            {job.jobCategory ?? (job.consultantJob ? "Consultoría SAP" : "")}
          </span>
          <span sx={{ color: "primary" }}>
            <Building />
          </span>
          <span sx={{ fontSize: 0 }}>{job.jobCommute}</span>
        </div>

        {user.role === "ADMIN" && (
          <button
            sx={{
              color: "text",
              border: "none",
              outline: "none",
              px: 2,
              py: 1,
              fontWeight: "heading",
              fontSize: 2,
              cursor: "pointer",
              bg: "muted",
            }}
            onClick={() => handleDelete(job._id)}
          >
            Eliminar
          </button>
        )}
      </div>
    </div>
  );
};

const Candidates = ({ job }: { job: Job }) => {
  const [applicantId, setApplicantId] = useState<string | null>();
  const handleClick = (id) => {
    setApplicantId((c) => (id === c ? null : id));
  };
  return job.candidates.length > 0 ? (
    <div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: [
            "2fr 1fr",
            "2fr 3fr 2fr 1fr",
            "2fr 1fr",
            "2fr 3fr 2fr 1fr",
            "2fr 3fr 2fr repeat(6, 1fr)",
          ],
          gap: 1,
          py: 3,
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "muted",
          px: 2,
        }}
      >
        <Heading as="h5">Nombre</Heading>
        <Heading as="h5" sx={{ display: ["none", "block", "none", "block"] }}>
          Email
        </Heading>
        <Heading as="h5" sx={{ display: ["none", "block", "none", "block"] }}>
          Teléfono
        </Heading>
        <Heading as="h5" sx={{ display: ["none", null, null, null, "block"] }}>
          Curriculum
        </Heading>
        <Heading as="h5" sx={{ display: ["none", null, null, null, "block"] }}>
          Modulos
        </Heading>
        <Heading as="h5" sx={{ display: ["none", null, null, null, "block"] }}>
          Rubros
        </Heading>
        <Heading as="h5" sx={{ display: ["none", null, null, null, "block"] }}>
          Habilidades
        </Heading>
        <Heading as="h5" sx={{ display: ["none", null, null, null, "block"] }}>
          Ingles
        </Heading>
        <Heading as="h5">Total</Heading>
      </div>

      {job.candidates.map(({ total, candidate, score }) => (
        <Fragment key={candidate._id}>
          <div
            onClick={() => handleClick(candidate?._id)}
            sx={{
              gap: 1,
              py: 3,
              display: "grid",
              gridTemplateColumns: [
                "2fr 1fr",
                "2fr 3fr 2fr 1fr",
                "2fr 1fr",
                "2fr 3fr 2fr 1fr",
                "2fr 3fr 2fr repeat(6, 1fr)",
              ],
              bg: "transparent",
              outline: "none",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid",
              borderColor: "muted",
              cursor: "pointer",
              px: 2,
              "&:hover": {
                bg: "muted",
              },
            }}
          >
            <Text>{candidate?.name}</Text>
            <Text sx={{ display: ["none", "block", "none", "block"] }}>
              {candidate?.email}
            </Text>
            <Text sx={{ display: ["none", "block", "none", "block"] }}>
              {candidate?.phone}
            </Text>
            <a
              sx={{ display: ["none", null, null, null, "block"] }}
              href={candidate.curriculum}
            >
              Link
            </a>
            <Text sx={{ display: ["none", null, null, null, "block"] }}>
              {score.find((s) => s.key === "module")?.value.toFixed(2)}
            </Text>
            <Text sx={{ display: ["none", null, null, null, "block"] }}>
              {score.find((s) => s.key === "field")?.value.toFixed(2)}
            </Text>
            <Text sx={{ display: ["none", null, null, null, "block"] }}>
              {score.find((s) => s.key === "softSkills")?.value.toFixed(2)}
            </Text>
            <Text sx={{ display: ["none", null, null, null, "block"] }}>
              {score.find((s) => s.key === "englishLvl")?.value.toFixed(2)}
            </Text>
            <Text>{total.toFixed(2)}</Text>
          </div>
          {applicantId === candidate._id && (
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: ["1fr", "1fr 1fr", null, "repeat(3,1fr)"],
                gridTemplateRows: ["1fr", "max-content max-content"],
                gap: 3,
                py: 2,
              }}
            >
              <div
                sx={{
                  display: "grid",
                  gap: 2,
                  gridRow: [null, "1/3"],
                  borderRight: (t) => [null, `1px solid ${t.colors.blue[0]}`],
                }}
              >
                <Heading sx={{ display: ["block"] }} as="h3">
                  Evaluación:
                </Heading>
                {score.map((score) => (
                  <div sx={{ textAlign: "left" }}>
                    <Text
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      <b>{score.key}</b>: {score.value.toFixed(2)}
                    </Text>
                    <Text
                      sx={{
                        fontSize: [1],
                      }}
                    >
                      {score.message}
                    </Text>
                  </div>
                ))}
              </div>
              <div
                sx={{
                  display: "grid",
                  gap: 2,
                  textAlign: "left",
                  gridRow: [null, null, "1/3"],
                  borderRight: (t) => [
                    null,
                    null,
                    `1px solid ${t.colors.blue[0]}`,
                  ],
                }}
              >
                <Heading as="h3">Datos Personales</Heading>
                <Text>
                  <b>Nombre:</b> {candidate?.name}
                </Text>
                <Text>
                  <b>
                    {candidate?.identification?.type ?? "Indentificación"}:{" "}
                  </b>
                  {candidate?.identification?.number ?? "-"}
                </Text>
                <Text>
                  <b>Telefono: </b>
                  {candidate?.phone ?? "-"}
                </Text>
                <Text>
                  <b>Email: </b>
                  {candidate?.email ?? "-"}
                </Text>
                <Text>
                  <b>Fecha de Nacimiento: </b>
                  {candidate?.birthdate ?? "-"}
                </Text>
                <Text>
                  <b>Salario en Planilla: </b>
                  {candidate?.salary?.payroll ?? "-"}
                </Text>
                <Text>
                  <b>Salario en RxH: </b>
                  {candidate?.salary?.receipt ?? "-"}
                </Text>
                <Text>
                  <b>Disponibilidad: </b>
                  {candidate?.availability?.inmediate
                    ? "Inmediata"
                    : candidate?.availability?.date ?? "-"}
                </Text>
              </div>
              <div
                sx={{
                  display: ["none", null, null, "flex"],
                  flexDirection: "column",
                  gap: 2,
                  gridRow: ["1/2"],
                }}
              >
                <Heading as="h3">Modulos:</Heading>
                {candidate?.modules?.map((module, index) => (
                  <div
                    sx={{
                      border: (t) => `1px solid ${t.colors.blue[0]}`,
                      p: 1,
                    }}
                    key={index}
                  >
                    <Text>
                      <b>Modulo:</b> {module.module}
                    </Text>
                    <Text>
                      <b>Experiencia: </b> {module.experience} años.
                    </Text>
                    {module.certified && (
                      <a
                        href={module.certificate}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Certificado
                      </a>
                    )}
                  </div>
                ))}
              </div>
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  gridRow: ["2/3"],
                }}
              >
                <Heading as="h3">Rubros:</Heading>
                <div>
                  {candidate.fields?.map((field, index) => (
                    <Text sx={{ fontSize: 0 }} key={index}>
                      {field}
                    </Text>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  ) : (
    <div>No hay postulantes aún</div>
  );
};

const EditJob = ({ jobId }: { jobId: string }) => {
  return (
    <div>
      <Heading as="h2">Editar Empleo</Heading>
      <ul>
        <li>
          <Link to={`/app/${jobId}/editar-info`}>Editar información</Link>
        </li>
        <li>
          <Link to={`/app/${jobId}/editar-habilidades`}>
            Editar habilidades
          </Link>
        </li>
        <li>
          <Link to={`/app/${jobId}/editar-rubros`}>Editar rubros</Link>
        </li>
        <li>
          <Link to={`/app/${jobId}/editar-modulos`}>Editar módulos</Link>
        </li>
        <li>
          <Link to={`/app/${jobId}/editar-evaluacion`}>Editar evaluación</Link>
        </li>
      </ul>
    </div>
  );
};

export const JobDetailPage = ({
  user,
  jobId,
}: {
  user: User;
  jobId: string;
}) => {
  const [navigation, setNavigation] = useState<number>(0);
  const [updateJob] = useMutation(UPDATE_JOB, {
    refetchQueries: [{ query: GET_ALL_JOBS }],
  });
  let bgColor = "muted";
  let color = "text";

  const handleUpdate = async ({ status }: { status: Status }) => {
    const input: MutationUpdateJobArgs = {
      id: job._id,
      jobInput: {
        status: status,
      },
    };
    updateJob({
      variables: {
        ...input,
      },
    });
  };
  const { data, loading, error } = useQuery<{ getJob: Job }>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });
  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );
  const job = data.getJob;
  switch (job.status) {
    case "PENDING":
      bgColor = "lemonchiffon";
      break;
    case "UNPUBLISHED":
      bgColor = "muted";
      break;
    case "REVIEW":
      bgColor = "#DAF9DA";
      break;
    case "FINISHED":
      bgColor = "header";
      color = "background";
      break;
    default:
      bgColor = "muted";
  }
  return (
    <div sx={{ display: "grid", gap: 3 }}>
      {/* BREADCRUMB */}
      <div
        sx={{
          borderBottom: "1px solid",
          borderColor: "muted",
          p: 2,
          display: "flex",
          alignItems: "left",
          gap: 1,
          flexDirection: ["column"],
        }}
      >
        <div sx={{ display: "flex", alignItems: "center" }}>
          <Link
            to="/app/"
            sx={{ color: "text", textTransform: "capitalize", fontSize: 0 }}
          >
            Empleos{" "}
          </Link>
          /
          <Link
            to={`/app/${job._id}`}
            sx={{ color: "text", textTransform: "capitalize", fontSize: 0 }}
          >
            {" "}
            {job.title}
          </Link>
        </div>
        <div
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "repeat(2, max-content)",
            ml: "auto",
          }}
        >
          <Text
            sx={{
              bg: bgColor,
              color: color,
              p: 1,
              fontSize: 0,
              borderRadius: "4px",
            }}
          >
            {job.status}
          </Text>
          <div sx={{ ml: ["auto"] }}>
            {user.role === "ADMIN" && job.status === "UNPUBLISHED" && (
              <Button
                sx={{ py: "2px!important" }}
                onClick={() =>
                  handleUpdate({ status: "PENDING" as Status.Pending })
                }
              >
                Publicar
              </Button>
            )}
            {user.role === "ADMIN" && job.status === "PENDING" && (
              <Button
                sx={{ py: "2px!important" }}
                onClick={() =>
                  handleUpdate({ status: "REVIEW" as Status.Review })
                }
              >
                Revisar
              </Button>
            )}
            {user.role === "ADMIN" && job.status === "REVIEW" && (
              <Button
                sx={{ py: "2px!important" }}
                onClick={() =>
                  handleUpdate({ status: "FINISHED" as Status.Finished })
                }
              >
                Finalizar
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* NAVIGATION ADMIN */}
      {user?.role === "ADMIN" && (
        <div
          sx={{
            display: "flex",
            gap: 3,
            p: 2,
            borderBottom: "1px solid",
            borderColor: "muted",
          }}
        >
          <button
            sx={{
              color: navigation === 0 ? "primary" : "text",
              border: "none",
              outline: "none",
              bg: "transparent",
              px: 2,
              py: 1,
              textDecoration: navigation === 0 && "underline",
              fontWeight: navigation === 0 && "heading",
              fontSize: 1,
              cursor: "pointer",
            }}
            onClick={() => setNavigation(0)}
          >
            Descripción
          </button>
          <button
            sx={{
              color: navigation === 1 ? "primary" : "text",
              border: "none",
              outline: "none",
              bg: "transparent",
              px: 2,
              py: 1,
              textDecoration: navigation === 1 && "underline",
              fontWeight: navigation === 1 && "heading",
              fontSize: 1,
              cursor: "pointer",
            }}
            onClick={() => setNavigation(1)}
          >
            Postulantes
          </button>
          <button
            sx={{
              color: navigation === 3 ? "primary" : "text",
              border: "none",
              outline: "none",
              bg: "transparent",
              px: 2,
              py: 1,
              textDecoration: navigation === 3 && "underline",
              fontWeight: navigation === 3 && "heading",
              fontSize: 1,
              cursor: "pointer",
            }}
            onClick={() => setNavigation(2)}
          >
            Editar
          </button>
        </div>
      )}

      {navigation === 0 && <JobDescription job={job} user={user} />}
      {navigation === 1 && <Candidates job={job} />}
      {navigation === 2 && <EditJob jobId={jobId} />}
    </div>
  );
};
