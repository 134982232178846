/**@jsx jsx */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Formik, Form, FormikHelpers, setIn } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Heading, jsx, Text } from "theme-ui";
import * as Yup from "yup";
import { DrawIcon } from "../../icons";

import { InputField } from "../form/input";
import { CheckBox } from "../form/checkbox";
import { BreadCrumbs } from "../navigation/bread-crumbs";
import { Layout } from "../layout/main-layout";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import {
  ExperienceInput,
  MutationCreateExperienceArgs,
  User,
} from "../../apollo/schemas/api";
import { CREATE_EXPERIENCE } from "../../apollo/mutations/experiences/createExperience";
import { GET_EXPERIENCE } from "../../apollo/queries/experiences/getExperience";
import { UPDATE_EXPERIENCE } from "../../apollo/mutations/experiences/updateExperience";
import { Link } from "gatsby";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const today = new Date();

const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .max(today, "Debe ser menor a la fecha de hoy")
    .required("Obligatorio"),
  currentJob: Yup.boolean(),
  endDate: Yup.date().when("currentJob", {
    is: false,
    then: (fieldSchema) =>
      fieldSchema
        .max(today, "Debe ser menor a la fecha de hoy")
        .required("Obligatorio"),
  }),
  position: Yup.string()
    .min(2, "Muy corto!")
    .max(50, "Muy largo!")
    .required("Obligatorio"),
  description: Yup.string()
    .min(2, "Muy corto!")
    .max(50, "Muy largo!")
    .required("Obligatorio"),
  referral: Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Obligatorio"),
    name: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
    phone: Yup.string()
      .matches(phoneRegExp, "El número no es válido.")
      .required("Obligatorio"),
    company: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
    position: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
  }),
});

export const UserReferences: React.FC<{ user: User }> = ({ user }) => {
  const [savedExperiences, saveExperiences] = useState<
    { id: string; companyName: string }[]
  >(
    user
      ? user.experiences.map((item) => ({
          id: item._id,
          companyName: item.referral.company,
        }))
      : []
  );
  const [thankYou, setThankYou] = useState(false);
  const [initialValues, setInitialValues] = useState<ExperienceInput>({
    currentJob: false,
    description: "",
    endDate: "",
    position: "",
    startDate: "",
    referral: {
      company: "",
      email: "",
      name: "",
      phone: "",
      position: "",
    },
  });
  const [selectedRef, setSelectedRef] = useState<string>(null);

  const idRef = useRef<string>(null);

  const [createExperience] = useMutation(CREATE_EXPERIENCE, {
    onError: (error) => {
      console.log({ error });
    },
  });
  const [updateExperience] = useMutation(UPDATE_EXPERIENCE, {
    onError: (error) => {
      console.log({ error });
    },
  });
  const [getExperience, { data, loading }] = useLazyQuery<{
    getExperience: ExperienceInput;
  }>(GET_EXPERIENCE);
  const handleSubmit = async ({
    values,
    actions = null,
    redirect = false,
  }: {
    values: ExperienceInput;
    actions?: FormikHelpers<ExperienceInput>;
    redirect?: boolean;
  }) => {
    const payload: MutationCreateExperienceArgs = {
      experienceInput: { ...values },
    };
    try {
      const response = selectedRef
        ? await updateExperience({
            variables: {
              ...payload,
              id: selectedRef,
            },
            refetchQueries: [{ query: GET_CURRENT_USER }],
          })
        : await createExperience({
            variables: {
              ...payload,
            },
            refetchQueries: [{ query: GET_CURRENT_USER }],
          });

      if (response && response.data) {
        if (redirect) {
          setThankYou(true);
        } else {
          if (!selectedRef) {
            saveExperiences((prev) => [
              ...prev,
              {
                companyName: values.referral.company,
                id: response.data.createExperience._id,
              },
            ]);
          }
        }
      }
    } catch (err) {
      console.log({ err });
    } finally {
      setSelectedRef(null);
      actions && actions.resetForm();
      setInitialValues({
        currentJob: false,
        description: "",
        endDate: "",
        position: "",
        startDate: "",
        referral: {
          company: "",
          email: "",
          name: "",
          phone: "",
          position: "",
        },
      });
    }
  };

  const findExperience = async (id) => {
    setSelectedRef(id);
    console.log("HERE");
    if (id) {
      await getExperience({
        variables: {
          id,
        },
      });
    }
  };

  useEffect(() => {
    console.log({ selectedRef });
    if (data && !loading && selectedRef) {
      setInitialValues({
        currentJob: data.getExperience.currentJob,
        description: data.getExperience.description,
        endDate: data.getExperience.endDate,
        position: data.getExperience.position,
        referral: {
          company: data.getExperience.referral.company,
          name: data.getExperience.referral.name,
          email: data.getExperience.referral.email,
          phone: data.getExperience.referral.phone,
          position: data.getExperience.referral.position,
        },
        startDate: data.getExperience.startDate,
      });
    }
  }, [data]);
  if (thankYou) {
    return (
      <Layout>
        <div
          sx={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center",
            py: 126,
          }}
        >
          <Heading as="h1">Gracias por registrarte</Heading>
          <p>Te agradecemos por dejarnos tus datos, ahora podrás postularte.</p>
          <Link
            sx={{
              bg: "primary",
              boxShadow:
                "0px 0px 1px rgba(40,41,61,0.04), 0px 2px 4px rgba(96,97,112,0.16)",
              display: "grid",
              p: 2,
              textDecoration: "none",
              color: "background",
              borderRadius: 3,
              mb: 3,
              width: "240px",
              textAlign: "center",
            }}
            to="/es/bolsa-de-trabajo"
          >
            Bolsa de trabajo
          </Link>
          <Link
            sx={{
              bg: "header",
              boxShadow:
                "0px 0px 1px rgba(40,41,61,0.04), 0px 2px 4px rgba(96,97,112,0.16)",
              display: "grid",
              p: 2,
              textDecoration: "none",
              borderRadius: 3,
              color: "background",
              width: "240px",
              textAlign: "center",
            }}
            to="/app"
          >
            Mi Cuenta
          </Link>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <BreadCrumbs step={6} steps={6} />
        <Heading as="h1">Acerca de tus referencias</Heading>
        <p>Cuentanos de tus experiencias laborales.</p>
        {savedExperiences.length > 0 && (
          <>
            <h3>Experiencias Guardadas: </h3>
            <ul sx={{ listStyleType: "none", p: 0, m: 0, mb: 4 }}>
              {savedExperiences.map((item) => (
                <li
                  sx={{
                    bg: selectedRef !== item.id ? "primary" : "header",
                    px: 2,
                    py: 3,
                    color: "background",
                    mt: 2,
                    borderRadius: "12px",
                    fontSize: 2,
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    display: "flex",
                    cursor: "pointer",
                    "&:hover": {
                      bg: "header",
                    },
                  }}
                  key={item.id}
                  onClick={() => {
                    findExperience(item.id);
                  }}
                >
                  {item.companyName}
                  {selectedRef !== item.id && (
                    <span sx={{ ml: "auto", mr: "2" }}>
                      <DrawIcon />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ isSubmitting, values }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <div sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
                <Fragment>
                  <fieldset
                    sx={{ borderColor: "muted", borderStyle: "dashed" }}
                  >
                    <legend sx={{ fontSize: 1 }}>Descripción Laboral</legend>
                    <div
                      sx={{
                        display: "grid",
                        gap: 3,
                      }}
                    >
                      <InputField
                        name={`position`}
                        type={"text"}
                        label={"Puesto"}
                        inputType={"input"}
                      />
                      <InputField
                        name={`startDate`}
                        type={"date"}
                        label={"Desde"}
                        inputType={"input"}
                      />
                      <div sx={{ px: 3, display: "grid", gap: 2 }}>
                        <Text>¿Trabajo Actual?</Text>
                        <CheckBox name={`currentJob`} type="checkbox" />
                      </div>
                      {!values.currentJob && (
                        <InputField
                          name={`endDate`}
                          type={"date"}
                          label={"Hasta"}
                          inputType={"input"}
                        />
                      )}
                      <InputField
                        name={`description`}
                        type={"text"}
                        label={"Descripción"}
                        inputType={"textarea"}
                      />
                    </div>
                  </fieldset>
                  <fieldset
                    sx={{
                      borderColor: "muted",
                      borderStyle: "dashed",
                      display: "grid",
                      gap: 3,
                    }}
                  >
                    <legend sx={{ fontSize: 1 }}>Referencia Laboral</legend>

                    <InputField
                      name={`referral.company`}
                      type={"text"}
                      label={"Empresa"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.name`}
                      type={"text"}
                      label={"Nombre de Referencia"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.phone`}
                      type={"tel"}
                      label={"Teléfono de Referencia"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.email`}
                      type={"email"}
                      label={"Email de Referencia"}
                      inputType={"input"}
                    />
                    <InputField
                      name={`referral.position`}
                      type={"text"}
                      label={"Cargo"}
                      inputType={"input"}
                    />
                  </fieldset>
                </Fragment>
              </div>
              <Button
                type="submit"
                aria-label="submit"
                sx={{
                  bg: "muted",
                  color: "header",
                  ":hover": {
                    bg: "header",
                    color: "background",
                  },
                }}
              >
                {selectedRef ? `Editar Referencia` : `+ Agregar Referencia +`}
              </Button>
              <Button
                type="button"
                sx={{
                  width: "100%",
                }}
                onClick={() => handleSubmit({ values, redirect: true })}
              >
                Finalizar
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
