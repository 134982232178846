import { gql } from "@apollo/client";
import { USER_FRAGEMENT } from "../../fragments/userFragment";

export const CREATE_USER = gql`
  ${USER_FRAGEMENT}
  mutation($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      token
      user {
        ...UserFragemnt
      }
    }
  }
`;
