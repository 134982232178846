import React, { Dispatch, SetStateAction } from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import { User } from "../apollo/schemas/api";

export type Props = {
  component: React.FC<{
    user?: User;
    setStep?: Dispatch<SetStateAction<number>>;
  }>;
  user?: User;
  setStep?: Dispatch<SetStateAction<number>>;
} & RouteComponentProps;

export type ProtectedRouteProps = {
  component: React.FC<{
    user?: User;
    setStep?: Dispatch<SetStateAction<number>>;
  }>;
  user: User | null;
  setStep?: Dispatch<SetStateAction<number>>;
} & RouteComponentProps;

export const Route: React.FC<Props> = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  user,
  ...rest
}) => {
  const isLoggedIn = !!localStorage.getItem("token");
  if (!isLoggedIn || user === null) {
    localStorage.removeItem("token");
    navigate("/app/ingresar");
    return <div>Estas siendo redirigido</div>;
  }
  const props = { user: user, ...rest };
  return <Component {...props} user={user} />;
};
