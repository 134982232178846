import { gql } from "@apollo/client";
import { EXPERIENCE_FRAGMENT } from "../../fragments/experienceFragment";

export const UPDATE_EXPERIENCE = gql`
  ${EXPERIENCE_FRAGMENT}
  mutation($id: String!, $experienceInput: ExperienceInput!) {
    updateExperience(id: $id, experienceInput: $experienceInput) {
      ...ExperienceFragment
    }
  }
`;
