/**@jsx jsx */
import { useMutation, useQuery } from "@apollo/client";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import { navigate } from "gatsby-link";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, jsx, Button, Spinner } from "theme-ui";
import { UPDATE_USER } from "../../apollo/mutations/users/updateUser";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import { ModuleInput, User } from "../../apollo/schemas/api";
import { CheckBox } from "../form/checkbox";
import { InputField } from "../form/input";
import { SelectField } from "../form/select";
import { UploadFile } from "../form/upload-file";
import { Layout } from "../layout/main-layout";

const modulesArray = [
  { id: 2, value: "CO", name: "CO" },
  { id: 3, value: "FI", name: "FI" },
  { id: 4, value: "HCM", name: "HCM" },
  { id: 5, value: "MM", name: "MM" },
  { id: 6, value: "SD", name: "SD" },
  { id: 7, value: "PP", name: "PP" },
  { id: 8, value: "PM", name: "PM" },
  { id: 9, value: "PS", name: "PS" },
  { id: 10, value: "ABAP", name: "ABAP" },
  { id: 11, value: "WM", name: "WM" },
  { id: 12, value: "CS", name: "CS" },
  { id: 13, value: "BI", name: "BI" },
  { id: 14, value: "B1", name: "B1" },
  { id: 15, value: "SCM", name: "SCM" },
  { id: 16, value: "CRM", name: "CRM" },
  { id: 17, value: "SDK", name: "SDK" },
  { id: 18, value: "BASIS", name: "BASIS" },
];

declare global {
  interface Window {
    cloudinary: any;
  }
}

interface IValues {
  module: string;
  experience: string;
  certified: boolean;
}

export const SapModuleEdit = () => {
  const [cloudinary, setCloudinary] = useState(null);
  const [cloudResponse, setCloudResponse] = useState<
    { secureURL: string; index: number; filename: string }[]
  >([
    {
      filename: "",
      index: 0,
      secureURL: "",
    },
  ]);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const indexRef = useRef<number>();

  indexRef.current = activeIndex;

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onCompleted: () => {
      navigate("/app/editar/");
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const {
    data,
    loading,
  }: { data: { getCurrentUser: User }; loading: boolean } = useQuery(
    GET_CURRENT_USER
  );

  const handSubmitForm = async ({
    values,
    actions,
  }: {
    values: { modules: IValues[] };
    actions: FormikHelpers<{ modules: IValues[] }>;
  }) => {
    const moduleInput: ModuleInput[] = values.modules.map((module, index) => ({
      certified: module.certified,
      module: module.module,
      experience: module.experience.toString(),
      certificate: cloudResponse[index].secureURL,
    }));
    await updateUser({
      variables: {
        userInput: {
          modules: moduleInput,
        },
      },
    });
  };

  const handleUploadClick = (i) => {
    setActiveIndex(i);
    cloudinary.open();
  };

  const handleUpload = ({ error, result }) => {
    if (!error && result && result.event === "success") {
      setCloudResponse((c) => {
        const findIndex = c.findIndex(
          (item) => item.index === indexRef.current
        );
        if (findIndex >= 0) {
          c[findIndex] = {
            ...c[findIndex],
            secureURL: result.info.secure_url,
            filename: result.info.original_filename,
          };
        }
        return [...c];
      });
    }
  };

  const handleHover = () => {
    if (!cloudinary) {
      const cloud = window.cloudinary?.createUploadWidget(
        {
          cloudName: "praxisnet",
          uploadPreset: "praxisnet",
          sources: ["local", "url", "google_drive"],
        },
        (error, result) => handleUpload({ error, result })
      );
      setCloudinary(cloud);
    }
  };

  useEffect(() => {
    const initialiazeState = () => {
      const user = data?.getCurrentUser;
      user?.modules &&
        setCloudResponse(
          user.modules.map((mod, index) => ({
            secureURL: mod.certificate,
            filename: mod.module,
            index: index,
          }))
        );
    };
    if (data) {
      initialiazeState();
    }
  }, [data]);

  if (loading)
    return (
      <Layout>
        <div
          sx={{
            display: ["grid"],
            placeItems: ["center"],
            position: ["fixed"],
            top: [0],
            right: [0],
            left: [0],
            bottom: [0],
            zIndex: -1,
          }}
        >
          <Spinner />
        </div>
      </Layout>
    );

  const user: User = data?.getCurrentUser;

  return (
    <Layout>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <div
        sx={{
          py: [3, 5],
          maxWidth: 512,
          mx: "auto",
          width: "90vw",
        }}
      >
        <Heading as="h1">Experiencia SAP</Heading>
        <p>Cuentanos un poco acerca de tu experiencia en módulos SAP.</p>
        <Formik
          initialValues={{
            modules:
              !!user && user.modules
                ? user.modules
                : [
                    {
                      module: "",
                      experience: "",
                      certified: false,
                    },
                  ],
          }}
          onSubmit={(values, actions) => handSubmitForm({ values, actions })}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
                <FieldArray
                  name="modules"
                  render={({ remove, push }) => (
                    <div
                      sx={{ display: "flex", gap: 3, flexDirection: "column" }}
                      onMouseEnter={handleHover}
                    >
                      {values.modules.map((_, i) => (
                        <fieldset
                          key={i}
                          sx={{
                            display: "grid",
                            gap: 3,
                            borderColor: "muted",
                            borderStyle: "dashed",
                            gridTemplateColumns: ["1fr 1fr"],
                          }}
                        >
                          <legend>Módulo SAP</legend>
                          <SelectField
                            name={`modules[${i}].module`}
                            options={modulesArray}
                            label={"Modulo"}
                          />
                          <InputField
                            name={`modules[${i}].experience`}
                            type={"number"}
                            label={"Experiencia"}
                            inputType={"input"}
                          />
                          <div
                            sx={{ display: "grid", gap: 2, gridColumn: "1/3" }}
                          >
                            <Text>¿Estás certificado en este módulo?</Text>
                            <CheckBox
                              name={`modules[${i}].certified`}
                              type="checkbox"
                            />
                          </div>
                          {values.modules[i].certified && (
                            <div sx={{ gridColumn: "1/3" }}>
                              <UploadFile
                                fileName={cloudResponse[i]?.filename}
                                handleClick={handleUploadClick}
                                label={"Certificado"}
                                index={i}
                              />
                            </div>
                          )}
                          {values.modules.length >= 2 && (
                            <Button
                              type="button"
                              onClick={() => {
                                remove(i);
                                setCloudResponse((c) =>
                                  c.filter((item) => item.index !== i)
                                );
                              }}
                              sx={{
                                bg: "transparent",
                                color: "muted",
                                gridColumn: "1/3",
                                cursor: "pointer",
                                ":hover": {
                                  bg: "transparent",
                                  color: "primary",
                                },
                              }}
                            >
                              - Eliminar Modulo -
                            </Button>
                          )}{" "}
                        </fieldset>
                      ))}
                      <Button
                        type="button"
                        onClick={() => {
                          push({
                            module: "",
                            experience: "",
                            certified: false,
                          });
                          setCloudResponse((c) => [
                            ...c,
                            {
                              filename: "",
                              index: c.length,
                              secureURL: "",
                            },
                          ]);
                        }}
                        sx={{
                          bg: "muted",
                          color: "header",
                          ":hover": {
                            bg: "header",
                            color: "background",
                          },
                        }}
                      >
                        + Agregar Modulo +
                      </Button>
                    </div>
                  )}
                />
                <input
                  aria-label="submit"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    variant: "buttons.primary",
                    border: "none",
                    outline: "none",
                  }}
                  value={isSubmitting ? "Guardando..." : "Guardar"}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};
