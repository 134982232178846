/**@jsx jsx */
import { useMutation } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import { Link, navigate } from "gatsby";
import React from "react";
import { Button, Heading, jsx } from "theme-ui";
import { PASSWORD_CHANGE } from "../../apollo/mutations/auth/passwordChange";
import { InputField } from "../form/input";
import { Layout } from "../layout/main-layout";
import { BreadCrumbs } from "../navigation/bread-crumbs";

interface ILogin {
  password: string;
  "confirm-password": string;
}

export const PasswordChange: React.FC = () => {
  const [passwordChange, { data, error }] = useMutation(PASSWORD_CHANGE);
  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: ILogin;
    actions: FormikHelpers<ILogin>;
  }) => {
    const passwordMatch =
      values.password &&
      values["confirm-password"] &&
      values.password === values["confirm-password"];
    if (!passwordMatch) {
      actions.setErrors({
        password: "Las contraseñas no coinciden",
        "confirm-password": "Las contraseñas no coinciden",
      });
      return;
    }
    try {
      const response = await passwordChange({
        variables: {
          newPassword: values.password,
        },
      });
      if (response.data?.changePassword) {
        navigate("/app/");
      }
    } catch (err) {
      console.log({ err });

      throw err;
    }
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1">Cambia tu contraseña</Heading>
        <p>Asegúrate de elegir una buena constraseña.</p>
        <Formik
          initialValues={{
            password: "",
            "confirm-password": "",
          }}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{ pt: 5, display: "flex", gap: 4, flexDirection: "column" }}
            >
              <InputField
                name="password"
                label="Contraseña"
                type="password"
                inputType="input"
                autoComplete="new-password"
              />
              <InputField
                name="confirm-password"
                label="Confirma tu Contraseña"
                type="password"
                inputType="input"
              />

              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting
                  ? "Cambiando tu contraseña"
                  : "Cambiar mi contraseña"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
