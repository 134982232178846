/**@jsx jsx */
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Heading, jsx } from "theme-ui";
import * as Yup from "yup";
import { subYears } from "date-fns";

import { InputField } from "../form/input";
import { CheckBox } from "../form/checkbox";
import { SelectField } from "../form/select";
import { UPDATE_USER } from "../../apollo/mutations/users/updateUser";
import { BreadCrumbs } from "../navigation/bread-crumbs";
import { Layout } from "../layout/main-layout";
import { navigate } from "gatsby-link";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import { User } from "../../apollo/schemas/api";
import { UploadFile } from "../form/upload-file";
import { Helmet } from "react-helmet";

declare global {
  interface Window {
    cloudinary: any;
  }
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const today = new Date();

const birthdayMin = subYears(today, 18);

interface IUserProfile {
  phone: string;
  identification: {
    type: string;
    number: string;
  };
  birthdate: string;
  address: {
    city: string;
    country: string;
  };
  salary: {
    payroll: string;
    receipt: string;
  };
  availability: {
    inmediate: boolean;
    date: string;
  };
  isConsultant: boolean;
}

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, "El número no es válido.")
    .required("Obligatorio"),
  identification: Yup.object().shape({
    number: Yup.string().required("El DNI o CE es obligatorio"),
  }),
  birthdate: Yup.date()
    .max(birthdayMin, "Debe ser mayor a 18 años")
    .required("Requerido"),
  address: Yup.object().shape({
    city: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
    country: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
  }),
  salary: Yup.object().shape({
    payroll: Yup.string()
      .min(2, "Muy corto!")
      .max(50, "Muy largo!")
      .required("Obligatorio"),
    receipt: Yup.string().min(2, "Muy corto!").max(50, "Muy largo!"),
  }),
  availability: Yup.object().shape({
    inmediate: Yup.boolean(),
    date: Yup.date().when("inmediate", {
      is: false,
      then: (fieldSchema) =>
        fieldSchema.default(undefined).required("Requerido"),
    }),
  }),
});

export const UserProfile: React.FC = () => {
  const [cloudinary, setCloudinary] = useState(null);
  const [cloudResponse, setCloudResponse] = useState<
    { secureURL: string; index: number; filename: string }[]
  >([
    {
      filename: "",
      index: 0,
      secureURL: "",
    },
  ]);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [redirectTo, setRedirectTo] = useState<string>(
    "/app/registrarme/modulos-sap"
  );
  const indexRef = useRef<number>();
  const redirectToRef = useRef<string>();

  indexRef.current = activeIndex;
  redirectToRef.current = redirectTo;
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      navigate(redirectToRef.current);
    },
    refetchQueries: [{ query: GET_CURRENT_USER }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: IUserProfile;
    actions: FormikHelpers<IUserProfile>;
  }) => {
    if (!values.isConsultant) {
      setRedirectTo("/app/");
    }
    if (!cloudResponse[indexRef.current].secureURL) {
      alert("Necesitas agregar tu CV en formato Word o PDF");
      return;
    }
    try {
      await updateUser({
        variables: {
          userInput: {
            ...values,
            identification: {
              ...values.identification,
              number: Number(values.identification.number),
            },
            onboarding: {
              finished: !values.isConsultant,
              link: values.isConsultant ? "/app/registrarme/modulos-sap" : null,
            },
            isConsultant: values.isConsultant,
            curriculum: cloudResponse[indexRef.current].secureURL,
          },
        },
      });
    } catch (err) {
      console.log({ err });
      throw err;
    }
  };
  const handleUpload = ({ error, result }) => {
    if (!error && result && result.event === "success") {
      setCloudResponse((c) => {
        const findIndex = c.findIndex(
          (item) => item.index === indexRef.current
        );
        if (findIndex >= 0) {
          c[findIndex] = {
            ...c[findIndex],
            secureURL: result.info.secure_url,
            filename: result.info.original_filename,
          };
        }
        return [...c];
      });
    }
  };

  const {
    data,
    loading,
  }: { data: { getCurrentUser: User }; loading: boolean } = useQuery(
    GET_CURRENT_USER
  );

  const handleHover = () => {
    if (!cloudinary) {
      const cloud = window.cloudinary?.createUploadWidget(
        {
          cloudName: "praxisnet",
          uploadPreset: "praxisnet",
          sources: ["local", "url", "google_drive"],
          multiple: false,
          folder: data.getCurrentUser._id,
        },
        (error, result) => handleUpload({ error, result })
      );
      setCloudinary(cloud);
    }
  };
  const handleUploadClick = (i) => {
    setActiveIndex(i);
    cloudinary.open();
  };

  useEffect(() => {
    const initialiazeState = () => {
      const user = data?.getCurrentUser;
      user?.curriculum &&
        setCloudResponse([
          {
            secureURL: user.curriculum,
            filename: user.curriculum,
            index: 0,
          },
        ]);
    };
    if (data) {
      initialiazeState();
    }
  }, [data]);

  return (
    <Layout>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <div
        sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}
        onMouseOver={handleHover}
      >
        <BreadCrumbs step={2} steps={6} />
        <Heading as="h1">Acerca tuyo</Heading>
        <p>Cuentanos un poco acerca de ti.</p>
        <Formik
          initialValues={{
            phone: "",
            identification: {
              type: "DNI",
              number: "",
            },
            birthdate: "",
            address: {
              city: "",
              country: "",
            },
            salary: {
              payroll: "",
              receipt: "",
            },
            availability: {
              inmediate: true,
              date: "",
            },
            isConsultant: true,
          }}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <fieldset sx={{ borderColor: "muted", borderStyle: "dashed" }}>
                <legend sx={{ fontSize: 1 }}>Documento de Indentidad</legend>
                <div
                  sx={{
                    display: "grid",
                    gridTemplateColumns: ["128px 1fr"],
                    gap: 3,
                  }}
                >
                  <SelectField
                    name="identification.type"
                    label="Tipo"
                    type="text"
                    options={[
                      {
                        id: 1,
                        value: "DNI",
                        name: "DNI",
                      },
                      {
                        id: 2,
                        value: "CE",
                        name: "CE",
                      },
                    ]}
                    value={values.identification.type}
                  />
                  <InputField
                    name="identification.number"
                    label="Número"
                    type="text"
                    inputType="input"
                  />
                </div>
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Datos personales</legend>

                <InputField
                  name="phone"
                  label="Teléfono"
                  type="tel"
                  inputType="input"
                />
                <InputField
                  name="birthdate"
                  label="Fecha de nacimiento"
                  type="date"
                  inputType="input"
                />
                <InputField
                  name="address.city"
                  label="Ciudad"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="address.country"
                  label="País"
                  type="text"
                  inputType="input"
                />
              </fieldset>

              <fieldset
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["1fr 1fr"],
                  gap: 3,
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Expectativa salarial</legend>
                <InputField
                  name="salary.payroll"
                  label="Planilla"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="salary.receipt"
                  label="RxH"
                  type="text"
                  inputType="input"
                />
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Disponibilidad</legend>
                <div>
                  <p>¿Inmediata?</p>
                  <CheckBox name="availability.inmediate" type="checkbox" />
                </div>
                {!values.availability.inmediate && (
                  <InputField
                    name="availability.date"
                    label="Fecha Disponibilidad"
                    type="date"
                    inputType="input"
                  />
                )}
              </fieldset>
              <div>
                <p>¿Eres Consultor?</p>
                <CheckBox name="isConsultant" type="checkbox" />
              </div>

              <UploadFile
                fileName={cloudResponse[0]?.filename}
                handleClick={handleUploadClick}
                label={"Certificado"}
                index={0}
              />
              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Cargando..." : "Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
