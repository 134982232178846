import { gql } from "@apollo/client";

export const GET_ALL_EXPERIENCES = gql`
  query {
    getAllReferrals {
      name
      phone
      email
      position
      company
      user {
        name
      }
    }
  }
`;
