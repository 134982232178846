import { gql } from "@apollo/client";
import { EXPERIENCE_FRAGMENT } from "../../fragments/experienceFragment";

export const CREATE_EXPERIENCE = gql`
  ${EXPERIENCE_FRAGMENT}
  mutation($experienceInput: ExperienceInput!) {
    createExperience(experienceInput: $experienceInput) {
      ...ExperienceFragment
    }
  }
`;
