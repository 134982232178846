import { gql } from "@apollo/client";

export const PASSWORD_CHANGE = gql`
  mutation($newPassword: String!) {
    changePassword(newPassword: $newPassword) {
      _id
      name
      email
      phone
      identification {
        number
        type
      }
      role
      birthdate
      address {
        country
        city
      }
      salary {
        payroll
        receipt
      }
      availability {
        inmediate
        date
      }
      fields
      softSkills {
        trabajoEquipo
        liderazgo
        comunicacionEfectiva
        escuchaActiva
        adaptabilidad
        creatividad
        espirituServicio
        comunicacion
        empatia
        proactividad
        gestionConflicto
        gestionCambio
      }
      englishLevel {
        speaking
        writing
        reading
      }
    }
  }
`;
