/**@jsx jsx */
import { useMutation } from "@apollo/client";
import { Formik, Form, FormikHelpers } from "formik";
import { navigate } from "gatsby-link";
import React from "react";
import { Button, Heading, jsx } from "theme-ui";
import { FORGOT_PASSWORD } from "../../apollo/mutations/auth/forgotPassword";
import { InputField } from "../form/input";
import { Layout } from "../layout/main-layout";

interface ILogin {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: ILogin;
    actions: FormikHelpers<ILogin>;
  }) => {
    try {
      const response = await forgotPassword({
        variables: {
          createIntentInput: {
            email: values.email,
          },
        },
      });
      if (response.data?.sendPasswordResetCode) {
        navigate("/app/confirmar-codigo");
        return;
      }
    } catch (err) {
      console.log({ err });
      actions.setErrors({
        email: "No hay una cuenta registrada con este correo",
      });
      throw err;
    }
  };

  return (
    <Layout>
      <div sx={{ pt: 5, maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1">Recupera tu contraseña</Heading>
        <p>Ingresa el correo que usaste para registrarte.</p>
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting }) => (
            <Form
              sx={{ pt: 5, display: "flex", gap: 4, flexDirection: "column" }}
            >
              <InputField
                name="email"
                label="Email"
                type="email"
                inputType="input"
              />
              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Enviando código" : "Enviar código"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
