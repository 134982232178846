/**@jsx jsx */
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Heading, jsx, Spinner, Text } from "theme-ui";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertFromHTML,
  ContentState,
  CompositeDecorator,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useMutation, useQuery } from "@apollo/client";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { InputField } from "../../form/input";
import { Layout } from "../../layout/main-layout";
import { BreadCrumbs } from "../../navigation/bread-crumbs";
import { UPDATE_JOB } from "../../../apollo/mutations/jobs/updateJob";
import { GET_JOB } from "../../../apollo/queries/jobs/getJob";
import { JobInput, Job, JobType, User } from "../../../apollo/schemas/api";
import { navigate } from "gatsby-link";
import { CheckBox } from "../../form/checkbox";

export const EditJob = ({ jobId, user }: { jobId: string; user: User }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleChange = (editorState) => {
    setEditorState(editorState);
  };
  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => {
      navigate(`/app/${jobId}/`);
    },
    onError: (err) => {
      console.log({ err });
    },
  });

  const handleSubmit = async ({
    values,
    actions,
  }: {
    values: JobInput & { jobType: boolean };
    actions: FormikHelpers<JobInput & { jobType: boolean }>;
  }) => {
    const contentState = editorState.getCurrentContent();
    const jobInput: JobInput & { jobType?: boolean } = {
      ...values,
      content: stateToHTML(contentState),
      type: values.jobType
        ? ("FULL" as JobType.Full)
        : ("PART" as JobType.Part),
    };
    delete jobInput.jobType;
    try {
      await updateJob({
        variables: {
          jobInput,
          id: jobId,
        },
      });
    } catch (err) {
      console.log({ err });
    }

    actions.setSubmitting(false);
  };

  const { data, loading } = useQuery<{ getJob: Job }>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });

  useEffect(() => {
    function findLinkEntities(contentBlock, callback, contentState) {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === "LINK"
        );
      }, callback);
    }
    function findImageEntities(contentBlock, callback, contentState) {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === "IMAGE"
        );
      }, callback);
    }

    const Link = (props) => {
      const { url } = props.contentState.getEntity(props.entityKey).getData();
      return <a href={url}>{props.children}</a>;
    };

    const updateState = () => {
      const blocksFromHTML = convertFromHTML(data.getJob.content);
      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
        {
          strategy: findImageEntities,
          component: Image,
        },
      ]);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state, decorator));
    };
    data?.getJob && updateState();
  }, [data]);

  if (loading)
    return (
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100",
        }}
      >
        <Spinner />
      </div>
    );

  const job = data.getJob;

  const initialValues: JobInput & { jobType: boolean } = {
    title: job.title,
    position: job.position,
    description: job.description,
    address: {
      city: job.address?.city ?? "",
      country: job.address?.country ?? "",
    },
    salary: {
      payroll: job.salary?.payroll ?? "",
      receipt: job.salary?.receipt ?? "",
    },
    availability: {
      date: job.availability?.date ?? "",
      inmediate: job.availability?.inmediate ?? false,
    },
    jobType: job.type === "FULL" ?? true,
    salaryRange: job.salaryRange ?? "",
  };

  return (
    <Layout>
      <div sx={{ py: [3, 5], maxWidth: 512, mx: "auto", width: "90vw" }}>
        <Heading as="h1" sx={{ textAlign: "left" }}>
          Edita {job.title}
        </Heading>
        <p>Edita la información principal.</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit({ values, actions })}
        >
          {({ isSubmitting, values }) => (
            <Form
              sx={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <InputField
                name="title"
                label="Título"
                type="text"
                inputType="input"
              />
              <InputField
                name="position"
                label="Cargo"
                type="text"
                inputType="input"
              />
              <InputField
                name="description"
                label="Descripción"
                type="text"
                inputType="textarea"
              />
              <div
                sx={{
                  boxShadow: "inset 0px 0.5px 4px rgba(96, 97, 112, 0.32)",
                  borderRadius: ["16px", "24px"],
                  height: 416,
                  p: 3,
                  overflow: "scroll",
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    lineHeight: 2,
                    fontWeight: "heading",
                    px: 3,
                    color: (t) => t.colors.dark[0],
                  }}
                >
                  Contenido:
                </Text>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleChange}
                />
              </div>
              <fieldset
                sx={{
                  borderColor: "muted",
                  borderStyle: "dashed",
                  display: "grid",
                  gap: 3,
                }}
              >
                <legend sx={{ fontSize: 1 }}>Dirección</legend>
                <InputField
                  name="address.city"
                  label="Ciudad"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="address.country"
                  label="País"
                  type="text"
                  inputType="input"
                />
              </fieldset>
              <fieldset
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["1fr 1fr"],
                  gap: 3,
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Expectativa salarial</legend>
                <InputField
                  name="salary.payroll"
                  label="Planilla"
                  type="text"
                  inputType="input"
                />
                <InputField
                  name="salary.receipt"
                  label="RxH"
                  type="text"
                  inputType="input"
                />
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Disponibilidad</legend>
                <div>
                  <p>¿Inmediata?</p>
                  <CheckBox name="availability.inmediate" type="checkbox" />
                </div>
                {!values.availability.inmediate && (
                  <InputField
                    name="availability.date"
                    label="Fecha Disponibilidad"
                    type="date"
                    inputType="input"
                  />
                )}
              </fieldset>
              <fieldset
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  borderColor: "muted",
                  borderStyle: "dashed",
                }}
              >
                <legend sx={{ fontSize: 1 }}>Informacion Extra</legend>
                <InputField
                  name="jobCommute"
                  label="¿Trabajo remoto?"
                  type="text"
                  inputType="input"
                  placeholder="Remoto temporal"
                />
                <div>
                  <p>¿Empleo de tiempo completo?</p>
                  <CheckBox name="jobType" type="checkbox" />
                </div>
                {!values.availability.inmediate && (
                  <InputField
                    name="salaryRange"
                    label="Rango de salario"
                    type="text"
                    inputType="input"
                  />
                )}
              </fieldset>
              <Button
                aria-label="submit"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                }}
              >
                {isSubmitting ? "Guardando" : "Guardar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
